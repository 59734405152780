export default {
    "name": "ReportQueueJobs",
    "kind": "HoudiniQuery",
    "hash": "ee0990a2763488da8f2cbc60fa8dea0ae64f80f851aa235a8a4d2241a56fed3e",

    "raw": `query ReportQueueJobs($pagination: PaginatedInput, $filter: ReportQueueFilter, $orderBy: [ReportQueueOrderBy!]!) {
  reportQueueJobs(pagination: $pagination, filter: $filter, orderBy: $orderBy) {
    data {
      id
      reportId
      type
      name
      quantity
      createdBy
      dateCreated
      destinationType
      destination
      status
      executionTime
      notes
      error
      parameters {
        key: parameterName
        value
        id
      }
    }
    info {
      totalItems
      pageNumber
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportQueueJobs": {
                "type": "ReportQueueResponse",
                "keyRaw": "reportQueueJobs(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ReportQueue",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "reportId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "reportId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Float",
                                        "keyRaw": "quantity",
                                        "visible": true
                                    },

                                    "createdBy": {
                                        "type": "String",
                                        "keyRaw": "createdBy",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "DateTime",
                                        "keyRaw": "dateCreated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "destinationType": {
                                        "type": "DestinationType",
                                        "keyRaw": "destinationType",
                                        "visible": true
                                    },

                                    "destination": {
                                        "type": "String",
                                        "keyRaw": "destination",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ReportQueueStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "executionTime": {
                                        "type": "Float",
                                        "keyRaw": "executionTime",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "error": {
                                        "type": "String",
                                        "keyRaw": "error",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "parameters": {
                                        "type": "ReportQueueParameter",
                                        "keyRaw": "parameters",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "key": {
                                                    "type": "String",
                                                    "keyRaw": "key",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "ReportQueueFilter",
            "orderBy": "ReportQueueOrderBy"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ReportQueueFilter": {
                "status": "ReportQueueStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6594ac9a44b2c91e684a22af985cb46937d70faf5932ca1690f7e81325e22eed";