import svelteComponent from './home.svelte'
import { getSession } from 'stores/session'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.home',
		route: 'home',
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: svelteComponent, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		resolve(_data, _parameters) {
			const session = getSession()
			return Promise.resolve({
				authToken: session.authToken,
			})
		},
		activate(_activateContext) {

		},
	})
}
