import template from './graph.ractive.html'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.sampling-history.graph',
		querystringParameters: [ 'url' ],
		route: 'graph',
		template: {
			template,
		},
		resolve(data, { url }) {
			return Promise.resolve({ url })
		},
	})
}
