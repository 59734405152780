import template from './product.html'
import pProps from 'p-props'
import { stringToBoolean } from '@isoftdata/utility-string'
import { groupItemsByObjectProperty } from '@isoftdata/utility-array'
import { klona } from 'klona'
import apiFetch from 'utility/api-fetch'
import { treeify } from '@isoftdata/svelte-table'
import setTreeDepth from 'utility/set-tree-depth'
import { v4 as uuid } from '@lukeed/uuid'
import pFileReader from 'promise-file-reader'
import { getSession } from 'stores/session'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

//Ractive component
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeSplitButton from '@isoftdata/split-button'
import makeModal from '@isoftdata/modal'
import makeSelect from '@isoftdata/select'
import makeSelectOneWay from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeCheckbox from '@isoftdata/checkbox'
import makeCollapsibleCard from '@isoftdata/collapsible-card'
import makeDateRange from '@isoftdata/date-range'
import makeTextArea from '@isoftdata/textarea'
import makeTagSelection from '../../../components/tag-selection'
import makeMightyMorphingInput from '../../../components/mighty-morphing-input'
import makeAttachmentComponent from '@isoftdata/attachment'

const FILE_BASE_URL = '__apiUrl__'.replace('/graphql', '/file/')

import { buildTranslatedConstants, thresholdsMap } from 'utility/constants'

const productTemplate = Object.freeze({
	id: null,
	active: true,
	category: ' ',
	description: null,
	inUseAtPlantIDs: [],
	name: '',
	parentProductId: null,
	parentProductUuid: null,
	productType: 'PRODUCT',
	tags: [],
	productFiles: [],
	children: [],
	childrenVisible: true,
	dirty: true,
})

const specificationTemplate = Object.freeze({
	analysisOption: null,
	boundaryType: 'UNACCEPTABLE',
	choice: '',
	constraint: 'MINIMUM',
	plantId: null,
	id: null,
	uuid: uuid(),
	productBatchId: null,
	requiredAnalysisOptionId: null,
	requiredChoice: null,
	requiredConstraint: null,
	severityClass: null,
	dirty: true,
	productId: null,
	productUuid: null,
})

export default function({ mediator, stateRouter, hasPermission, i18next }) {
	stateRouter.addState({
		name: 'app.product-management.product',
		route: 'product',
		querystringParameters: [ 'plantId', 'selectedProductTypeFilter', 'showInactive', 'showUnused', 'selectedProductId' ],
		defaultParameters: {
			plantId: () => getSession().siteId,
			selectedProductId: null,
			showInactive: false,
			showUnused: false,
			selectedProductTypeFilter: 'PRODUCT',
			canLeaveState: () => true,
		},
		template: {
			template,
			translate: i18next.t,
			components: {
				itTable: makeTable({
					methods: {
						filter(value) {
							try {
								const treedProducts = this.get('rows')
								const getNodes = (result, object) => {
									if (object.name.toLowerCase().includes(value.toLowerCase())) {
										result.push(object)
										return result
									}
									if (Array.isArray(object.children)) {
										const children = object.children.reduce(getNodes, [])
										if (children.length) {
											result.push({ ...object, children })
										}
									}
									return result
								}
								if (value === '') {
									return treedProducts
								}
								return treedProducts.reduce(getNodes, [])
							} catch (err) {
								console.error(err)
							}
						},
					},
					stickyHeader: true,
				}),
				itButton: makeButton(),
				itSplitButton: makeSplitButton(),
				itModal: makeModal(),
				itSelect: makeSelect({ twoway: true }),
				itSelectOneWay: makeSelectOneWay({ twoway: false }),
				itInput: makeInput({ twoway: false }),
				itCheckbox: makeCheckbox(),
				collapsibleCard: makeCollapsibleCard({ noIntro: true, noOutro: false, twoway: true }),
				itDateRange: makeDateRange(),
				itTextArea: makeTextArea({ twoway: false, lazy: false }),
				tagSelection: makeTagSelection({ mediator, twoway: true }),
				mightyMorphingInput: makeMightyMorphingInput({ twoway: false }),
				itAttachment: makeAttachmentComponent({
					deferredSaving: true,
					handleError: err => {
						if (typeof err === 'object' && err !== null && typeof err.message === 'string') {
							if (err.extensions?.code === 'ER_DUP_ENTRY') {
								alert(`Error: Duplicate Attachments not allowed`)
							} else {
								alert(`Error: ${err.message}`)
							}
						}
					},
				}),
			},
			computed: {
				hasUnsavedChanges() {
					return (
						this.getProductsToCreate().length ||
						this.getProductsToUpdate().length ||
						this.getProductsToDelete().length ||
						this.getTagsToCreate().length ||
						this.getTagsToUpdate().length ||
						this.getTagsToDelete().length ||
						this.getSpecificationsToCreate().length ||
						this.getSpecificationsToUpdate().length ||
						this.getSpecificationsToDelete().length
					)
				},
				/**
				 * @description - Returns the applicable plants for the selected product for the products card plant dropdown.
				 * @instance - Computed
				 * @returns {Array<{ id: number, name: string, code: string }>} - Plants that the user has access to
				 */
				selectedPlant() {
					return this.get('plants').find(plant => plant.id === this.get('plantId'))
				},

				/**
				 * @description - Indexes the products array with an unfilteredIndex property
				 * @instance - Computed
				 * @returns {Array} An array of all products with an index property added to each object
				 */
				indexedProducts() {
					const products = this.get('products')
					// The `originalIndex` of a row, computed by the table component, happens after the rows are filtered,
					// and we need to know what it was before the table filter was applied.
					// I didn't fix this in the table component because that would create a breaking change for all custom filter methods.
					return products.map((product, index) => {
						product.unfilteredIndex = index
						return product
					})
				},

				/**
				 * @description - Indexes the specifications array with an unfilteredIndex property
				 * @instance - Computed
				 * @returns {Array} An array of all analysis option choices with an index property added to each object
				 */
				indexedSpecifications() {
					const availableSpecifications = this.get('availableSpecifications')
					return availableSpecifications.map((specification, index) => ({ ...specification, unfilteredIndex: index }))
				},

				/**
				 * @description - Trees the products array with a child property and a depth property.
				 * @instance - Computed
				 * @returns {Array} An array of all products with a children property added to each object
				 */
				treedProducts() {
					const treeifiedObject = treeify(this.get('indexedProducts'), 'uuid', 'parentProductUuid')
					const depthSetObject = setTreeDepth({ tree: treeifiedObject, childListKeypath: 'children', depthKeypath: 'depth' })
					return depthSetObject
				},

				/**
				 * @description - finds the selected product from the currently selected product uuid
				 * @instance - Computed
				 * @returns {Object} The selected product object
				 */
				selectedProduct() {
					const selectedProductUuid = this.get('selectedProductUuid')
					return this.get('indexedProducts').find(product => product.uuid === selectedProductUuid)
				},

				/**
				 * @description - toggles a buttons disabled state based on whether or not a product is selected
				 * @instance - Computed
				 * @returns {Boolean} A boolean
				 * @default true
				 */
				disableButtonBasedOnSelectedProduct() {
					return !this.get('selectedProduct')
				},

				/**
				 * @description - getter for the selected product's index in the indexedProducts array
				 * @instance - Computed
				 * @returns {number} The index of the selected product
				 */
				selectedProductIndex() {
					const selectedProductUuid = this.get('selectedProductUuid')
					return this.get('indexedProducts').findIndex(product => product.uuid === selectedProductUuid)
				},

				/**
				 * @description - computed list of specifications for the selected product
				 * @instance - Computed
				 * @returns {Array} An array of specifications for the selected product
				 */
				selectedSpecifications() {
					const selectedProduct = this.get('selectedProduct')
					const availableSpecifications = this.get('indexedSpecifications')
					const selectedProductSpecification = availableSpecifications.reduce((acc, specification) => {
						if (specification.productUuid === selectedProduct?.uuid && specification.productBatchId === null) {
							acc.push(specification)
						}
						return acc
					}, [])
					return selectedProductSpecification
				},

				/**
				 * @description - a computed filteredList of specifications to get the selected specification object
				 * @instance - Computed
				 * @returns {object} the selected specification object
				 */
				selectedSpecification() {
					const selectedSpecifications = this.get('selectedSpecifications')
					const selectedSpecification = selectedSpecifications.find(specification => specification.uuid === this.get('selectedSpecificationUuid'))
					return selectedSpecification
				},

				/**
				 * @description - a computed object of uuids that have children where the key is the uuid of the parent and the value is an array of uuids of the children
				 * @instance - Computed
				 * @returns {object} a computed object of parent child relationships
				 */
				parentReference() {
					const products = this.get('products')
					const parentChildObject = {}
					products.forEach(product => {
						if (product.parentProductUuid) {
							parentChildObject[product.parentProductUuid] = parentChildObject[product.parentProductUuid] || []
							parentChildObject[product.parentProductUuid].push(product)
						} else {
							parentChildObject[product.uuid] = parentChildObject[product.uuid] || []
						}
					})
					return parentChildObject
				},

				/**
				 * @description - Returns selected option value type
				 * @instance - Computed
				 * @returns returns the value type of the selected option or 'TEXT' if there is no selected option or the selected option is a choice
				 */
				thresholdValueType() {
					const selectedOption = this.get('selectedOption')
					// Since we're entering choices not choosing one, we want to have an input not a select
					if (!selectedOption || selectedOption.valueType === 'CHOICE') {
						return 'TEXT'
					}
					return selectedOption.valueType
				},

				/**
				 * @description - A check to see if any unsaved changes have been made in the state
				 * @instance - Computed
				 * @returns {Boolean}
				 */
				canLeaveState() {
					const ractive = this
					const productsToCreate = ractive.getProductsToCreate()
					const productsToUpdate = ractive.getProductsToUpdate()
					const productsToDelete = ractive.getProductsToDelete()
					const specificationsToCreate = ractive.getSpecificationsToCreate()
					const specificationsToUpdate = ractive.getSpecificationsToUpdate()
					const specificationsToDelete = ractive.getSpecificationsToDelete()
					const tagsToCreate = ractive.getTagsToCreate()
					const tagsToUpdate = ractive.getTagsToUpdate()
					const tagsToDelete = ractive.getTagsToDelete()
					const isDirty =
						productsToCreate.length ||
						productsToUpdate.length ||
						productsToDelete.length ||
						specificationsToCreate.length ||
						specificationsToUpdate.length ||
						specificationsToDelete.length ||
						tagsToCreate.length ||
						tagsToUpdate.length ||
						tagsToDelete.length
					if (isDirty) {
						return false
					} else {
						return true
					}
				},

				/**
				 * @description - A permission lookup to see if the user has access to edit products
				 * @instance - Computed
				 * @returns {Boolean}
				 */
				canEditPlantSpecificFields() {
					const ractive = this
					return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', ractive.get('plantId'))
				},

				/**
				 * @description - A permission lookup to see if the user has access to edit global fields for the selected product or just the selected plant
				 *
				 * @instance - Computed
				 * @returns {Map<Number, Boolean>} -1 = selected plant only, 0 = all plants
				 */
				canEditGlobalFieldsMap() {
					const ractive = this
					// This needs to be checked on a per-product basis, so the user can't edit a product at a plant they don't have access to.
					// This also needs to be checked on a selected plant basis, so the user can't edit a product at a plant they don't have access to.
					const products = ractive.get('products')
					const thisPlantId = ractive.get('plantId')
					return products.reduce((acc, product) => {
						acc.set(
							product.uuid,
							product?.inUseAtPlantIDs?.every(plantId => hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)),
						)
						return acc
					}, new Map([ [ -1, hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', thisPlantId) ] ])) // -1 = selected plant only
				},

				userPlantPermissionMap() {
					const ractive = this
					const plants = ractive.get('nonPrivatePlants')
					const userAuthorizedPlantIds = ractive.get('authorizedPlantIDs') ?? []
					const productInUseAtPlantIds = ractive.get('selectedProduct.inUseAtPlantIDs') ?? []
					const userPlantPermissionMap = new Map()
					plants.forEach(plant => {
						userPlantPermissionMap.set(plant.id, { ...plant, permission: userAuthorizedPlantIds.includes(plant.id) && productInUseAtPlantIds.includes(plant.id) })
					})
					return userPlantPermissionMap
				},

				userHasPermissionInAllPlantsProductIsInUseAt() {
					const ractive = this
					const userPlantPermissionMap = ractive.get('userPlantPermissionMap')
					const productInUseAtPlantIds = ractive.get('selectedProduct.inUseAtPlantIDs') ?? []
					const userHasPermissionInAllPlantsProductIsInUseAt = productInUseAtPlantIds.every(plantId => userPlantPermissionMap.get(plantId)?.permission)
					return userHasPermissionInAllPlantsProductIsInUseAt
				},
			},
			pad(depth) {
				return `${depth * 1.5 }rem`
			},
			/**
			 * @description - User permission lookup for a specific product based on the canEditGlobalFieldsMap
			 * @instance - Ractive Function
			 * @param {Number} productUuid - The product uuid to check against defaults to -1 which is not a valid uuid and will check for the global permission
			 * @returns {Boolean}
			 */
			canEditGlobalFields(productUuid = -1) {
				const ractive = this
				/**@type {Map<string,boolean>} - Map of uuid -> permission bool*/
				const globalFieldsMap = ractive.get('canEditGlobalFieldsMap')
				return globalFieldsMap.get(productUuid) ?? false
			},

			/**
			 * @description - getter to check if the choice can be editied based on the selected plant and the canEditGlobalFields computed prop
			 * @instance - Ractive Function
			 * @param {Number} plantId - The plant id to check against
			 * @returns {Boolean}
			 */
			canEditChoice(plantId) {
				const ractive = this
				if (plantId === null) {
					// Choice/Threshold is "Global" if not at a plant
					return ractive.canEditGlobalFields(ractive.get('selectedProductUuid'))
				}
				// Choice/Threshold is this plant only
				if (Number.isInteger(plantId)) {
					return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)
				}
				// Shouldn't happen, but just in case
				return false
			},

			/**
			 * @description - A check to see if any products have duplicate names - called before saving
			 * @instance - Ractive Function
			 * @returns {Boolean} - True if there are duplicate names in the products array
			 */
			nameDuplicationCheck() {
				const ractive = this

				const products = ractive.get('products')

				const duplicateName = products.find(product => ractive.peerNameDuplicationCheck(product))

				if (duplicateName) {
					return true
				}
				const ancestorProductNames = products.reduce((acc, product) => {
					if (product.parentProductUuid === null) {
						acc.push(product.name)
					}
					return acc
				}, [])
				const duplicateAncestorName = ancestorProductNames.length !== new Set(ancestorProductNames).size
				if (duplicateAncestorName) {
					return true
				}

				return false
			},

			/**
			 * @description - A check to see if a product has the same name as any of its siblings (products with the same parent)
			 * @instance - Ractive Function
			 * @param {Object} product - The product to check for duplicate names against its peer group
			 * @returns {Boolean} - True if the product has a duplicate name in its peer group
			 */
			peerNameDuplicationCheck(product) {
				// This will be used to show a warning when a product has a duplicate name in its peer group
				const ractive = this

				const parentReference = ractive.get('parentReference')
				const children = parentReference[product?.parentProductUuid] ?? []
				const childrenNames = children.map(child => child.name)
				return childrenNames.length !== new Set(childrenNames).size
			},

			/**
			 * @description - Display discriminator for specifications to render the row or not
			 * @instance - Ractive Function
			 * @param {number} id
			 * @returns {boolean}
			 */
			hasViewPermissionForSpecificiation(id, plantId) {
				const ractive = this
				const applicableAnalysis = ractive.get('analysis')
				const plants = ractive.get('plants')
				const plant = plants.find(plant => plant.id === plantId)
				const loggedInPlant = ractive.get('session.siteId') ?? null
				if (plant?.private && loggedInPlant !== plantId) {
					return false
				}
				let applicableAnalysisIds = []
				applicableAnalysisIds = applicableAnalysis.map(analysis => analysis.id)
				applicableAnalysisIds.push(null) // null is so new specifications can be seen
				return applicableAnalysisIds.includes(id)
			},

			/**
			 * @description - toggle function to toggle the visibility of the children of a given product
			 * @instance - Ractive Function
			 * @param {Object} product - The product object to update
			 * @returns {undefined}
			 */
			toggleChildren(product) {
				const ractive = this
				const areChildrenVisible = product.childrenVisible

				ractive.updateProductKeypath(product.unfilteredIndex, 'childrenVisible', !areChildrenVisible, 'nonDirty')
			},

			/**
			 * @description - toggle function to unselect the selected product by setting relevant keypaths to null
			 * @instance - Ractive Function
			 * @returns {undefined}
			 */
			unselectProduct() {
				const ractive = this
				ractive.set({
					selectedProductId: null,
					selectedProductUuid: null,
					selectedTagId: null,
					selectedTagUuid: null,
				})
			},

			/**
			 * @description - a list of applicable analysis for the selected plant but shows analysis for plants if its already in use at a different plant
			 * @instance - Ractive Function
			 * @returns {Array} a computed list of applicable analysis
			 */
			applicableAnalysis(analysisId) {
				const ractive = this
				const analysis = ractive.get('analysis')
				const selectableAnalyses = ractive.get('selectableAnalyses')
				// should return all analysis that are selectable and the ananlysis input if its not already in the selectable list
				const alreadyHaveAnalysis = selectableAnalyses.find(selectableAnalysis => selectableAnalysis.id === analysisId)
				if (alreadyHaveAnalysis) {
					return selectableAnalyses
				}
				return [ ...selectableAnalyses, analysis.find(analysis => analysis.id === analysisId) ?? null ]
			},

			/**
			 * @description - function to get applicable plants for the selected specification
			 * @instance - Ractive Function
			 * @param {number} preSelectedPlantId
			 * @returns {object[]} - An array of plants that the user has access to that the selected product is in use at
			 */
			applicablePlants(preSelectedPlantId) {
				const ractive = this
				const userPlantPermissionMap = ractive.get('userPlantPermissionMap')
				const userHasAllInUsePlantsPermissions = ractive.get('userHasPermissionInAllPlantsProductIsInUseAt')
				const userPermissionedPlants = []

				if (userHasAllInUsePlantsPermissions) {
					// User has access to all plants that the product is in use at so they can set it to any of those plants or all plants
					userPermissionedPlants.push({ id: null, name: 'All Plants', code: 'All' })
				}
				if (preSelectedPlantId === null && !userHasAllInUsePlantsPermissions) {
					// The default is All Plants so we need to return all plants as an option
					userPermissionedPlants.push({ id: null, name: 'All Plants', code: 'All' })
				}
				userPlantPermissionMap.forEach((plant, plantId) => {
					if (plant?.permission) {
						userPermissionedPlants.push(plant)
					} else if (plantId === preSelectedPlantId) {
						// The user doesn't have access to the selected plant so we need to add it to the list of options (they can edit the product off of it but on reload it will be gone)
						userPermissionedPlants.push(plant)
					}
				})
				return userPermissionedPlants // The user has access to the selected plant so we don't need to add it to the list of options and we don't need to add the All Plants option
			},

			/**
			 * @description - Getter for list of thresholds tht are applicable for the selected specification
			 * @instance - Ractive Function
			 * @returns {Array} returns a map of Thresholds that are applicable for this specification
			 */
			applicableThresholds(boundryType) {
				const ractive = this
				const thresholdsMap = ractive.get('thresholdsWhenMap')
				// Don't show 'Invalid when' when it's not relevant
				if (boundryType === 'CHOICE' || boundryType === 'BOOLEAN' || boundryType === 'TEXT') {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { BOUNDARY, ...applicableThresholds } = thresholdsMap
					return applicableThresholds
				}
				return thresholdsMap
			},

			/**
			 * @description - getsApplicableSeverity Classes
			 * @instance - Ractive Function
			 * @param {number} plantId
			 * @returns {object[]} - An array of severity classes that are applicable for the selected plant
			 */
			applicableSeverityClasses(plantId) {
				const ractive = this
				const severityClasses = ractive.get('severityClasses')
				return severityClasses.filter(severityClass => severityClass.plantId === plantId)
			},

			/**
			 * @description - setter helper function to change some keypath value on a given product index.
			 * @instance - Ractive Function
			 * @param {Number} index - The index of the product to update
			 * @param {String} keypath - The keypath to update
			 * @param {Any} value - The value to set
			 * @param {String} type (number, nonDirty) - The type of value to set or side effect to perform (Dirty is the default)
			 * @returns {promise<undefined>}
			 */
			updateProductKeypath(index, keypath, value, type) {
				if (type === 'number') {
					value = Number(value)
				}
				if (type === 'nonDirty') {
					this.set({
						[`products.${index}.${keypath}`]: value,
					})
					return
				}
				this.set({
					[`products.${index}.dirty`]: !!this.get(`products.${index}.id`) || this.get(`products.${index}.dirty`),
					[`products.${index}.${keypath}`]: value,
				})
			},

			/**
			 * @descirption - setter helper function to change the inUseAtPlantIDs keypath value on a given product index. Since it has a different procedure than the other keypaths, it has its own function.
			 * @instance - Ractive Function
			 * @param {Number} index - The index of the product to update
			 * @param {String} keypath - The keypath to update
			 * @param {Any} value - The value to set
			 * @param {String} type- The type of value to set to cast if needed (number)
			 * @returns {undefined}
			 */
			updateProductInUse(index, keypath, value, type) {
				if (type === 'number') {
					value = Number(value)
				}
				const currentPlantId = this.get('selectedPlant.id')
				const currentInUseAtPlantIDs = this.get(`products.${index}.inUseAtPlantIDs`)
				let newInUseAtPlantIds = []
				if (value) {
					newInUseAtPlantIds = [ ...currentInUseAtPlantIDs, currentPlantId ]
				} else {
					newInUseAtPlantIds = currentInUseAtPlantIDs.filter(id => id !== currentPlantId)
				}
				this.set({
					[`products.${index}.dirty`]: !!this.get(`products.${index}.id`) || this.get(`products.${index}.dirty`),
					[`products.${index}.${keypath}`]: value,
					[`products.${index}.inUseAtPlantIDs`]: newInUseAtPlantIds,
				})
			},

			/**
			 * @description - setter helper function to change some keypath value on a given specification index.
			 * @instance - Ractive Function
			 * @param {Number} index - The index of the specification to update
			 * @param {String} keypath - The keypath to update
			 * @param {Any} value - The value to set
			 * @param {String} type - The type of value to set (number) number is passed to cast the value to a number
			 * @returns {undefined}
			 */
			updateSpecificationKeypath(index, keypath, value, type) {
				const ractive = this
				if (type === 'number') {
					value = Number(value)
				}
				let specificationMetadata
				if (keypath === 'analysisOption.analysis.id') {
					// set analysisOption.id to null
					specificationMetadata = {
						...ractive.get(`availableSpecifications.${index}.analysisOption`),
						id: null,
						valueType: null,
					}
				}

				if (keypath === 'analysisOption.id') {
					const analysisOptions = ractive.get('analysisOptions')
					const currentAnalysisOptionState = ractive.get(`availableSpecifications.${index}.analysisOption`)
					const foundAnalysisOption = analysisOptions.find(analysisOption => analysisOption.id === value)
					specificationMetadata = {
						...currentAnalysisOptionState,
						id: foundAnalysisOption.id,
						valueType: foundAnalysisOption.valueType,
					}
				}
				specificationMetadata
					? ractive.set({
						[`availableSpecifications.${index}.dirty`]: !!ractive.get(`availableSpecifications.${index}.id`) || ractive.get(`availableSpecifications.${index}.dirty`),
						[`availableSpecifications.${index}.analysisOption`]: specificationMetadata,
						[`availableSpecifications.${index}.${keypath}`]: value,
					})
					: ractive.set({
						[`availableSpecifications.${index}.dirty`]: !!ractive.get(`availableSpecifications.${index}.id`) || ractive.get(`availableSpecifications.${index}.dirty`),
						[`availableSpecifications.${index}.${keypath}`]: value,
					})
			},

			/**
			 * @description - aggregates all products that are to be updated into a single array
			 * @instance - Ractive Function
			 * @returns list of product objects that are to be updated
			 */
			getProductsToUpdate() {
				return this.get('products').filter(product => product.dirty && product.id && !product.deleted)
			},

			/**
			 * @description - aggregates all products that are to be created into a single array
			 * @instance - Ractive Function
			 * @returns list of product objects that are to be created
			 */
			getProductsToCreate() {
				return this.get('products').filter(product => product.dirty && !product.id && !product.deleted)
			},

			/**
			 * @description - aggregates all products that are to be deleted into a single array
			 * @instance - Ractive Function
			 * @returns list of product objects that are to be deleted
			 */
			getProductsToDelete() {
				return this.get('products')
					.filter(product => product.deleted && product.id)
					.map(product => product.id)
			},

			/**
			 * @description - aggregates all specifications that are to be created into a single array
			 * @instance - Ractive Function
			 * @returns list of specifications that are to be updated
			 */
			getSpecificationsToUpdate() {
				return this.get('availableSpecifications').filter(analysis => analysis.dirty && analysis.id && !analysis.deleted)
			},

			/**
			 * @description - aggregates all specifications that are to be created into a single array
			 * @instance - Ractive Function
			 * @returns list of specifications that are to be created
			 */
			getSpecificationsToCreate() {
				return this.get('availableSpecifications').filter(analysis => analysis.dirty && !analysis.id && !analysis.deleted)
			},

			/**
			 * @description - aggregates all specifications that are to be deleted into a single array
			 * @instance - Ractive Function
			 * @returns list of specifications that are to be deleted
			 */
			getSpecificationsToDelete() {
				return this.get('availableSpecifications')
					.filter(analysis => analysis.deleted && analysis.id)
					.map(analysis => analysis.id)
			},

			/**
			 * @description - aggregates all tags that are to be created into a single array
			 * @instance - Ractive Function
			 * @returns list of tags that are to be created
			 */
			getTagsToCreate() {
				return this.get('tags')
					.filter(tag => !tag.id && !tag.deleted)
					.map(({ active, entityType, name }) => ({ active, entityType, name }))
			},

			/**
			 * @description - aggregates all tags that are to be updated into a single array
			 * @instance - Ractive Function
			 * @returns list of tags that are to be updated
			 */
			getTagsToUpdate() {
				return this.get('tags')
					.filter(tag => tag.id && tag.dirty && !tag.deleted)
					.map(({ id, active, entityType, name }) => ({ id, active, entityType, name }))
			},

			/**
			 * @description - aggregates all tags that are to be deleted into a single array
			 * @instance - Ractive Function
			 * @returns list of tags that are to be deleted
			 */
			getTagsToDelete() {
				return this.get('tags')
					.filter(tag => tag.id && tag.deleted)
					.map(({ id }) => id)
			},

			/**
			 * @description - aggregates all products files into a single array
			 * @instance - Ractive Function
			 * @returns a computed list of all files for all products
			 */
			getAllFiles() {
				const ractive = this
				const products = ractive.get('products')
				return products.reduce((acc, product) => {
					if (product.productFiles) {
						product.productFiles.forEach(file => {
							acc.push({ ...file, productUuid: product.uuid })
						})
					}
					return acc
				}, [])
			},

			/**
			 * @description - a getter function to format all files for saving to the server
			 * @instance - Ractive Function
			 * @returns {Object<filesToAttach: Array<{ file: File, productUuid: string }>, filesToDetach: Array<{ file: File, productUuid: string }>>} - The formatted files for attaching and detaching in the format the mutation input expects
			 */
			getFormattedFiles() {
				const ractive = this
				const allFiles = ractive.getAllFiles()

				const files = allFiles.reduce(
					(acc, file) => {
						if (file.action === 'CREATE') {
							acc.filesToAttach.push(file)
						}
						if (file.action === 'DELETE') {
							acc.filesToDetach.set(file.productUuid, acc.filesToDetach.get(file.productUuid) ? [ ...acc.filesToDetach.get(file.productUuid), file.fileId ] : [ file.fileId ])
						}
						return acc
					},
					{ filesToAttach: [], filesToDetach: new Map() },
				)
				return files
			},

			/**
			 * @description - function to format all creates and updates of product objects
			 * @instance - Ractive Function
			 * @returns {object[]} - an array of objects to create or update sorted by depth in the tree set up to save with initial id assignment handled
			 */
			formatProductsForSave() {
				const ractive = this
				const treedProducts = ractive.get('treedProducts')
				const productsToCreate = ractive.getProductsToCreate()
				const productsToUpdate = ractive.getProductsToUpdate()
				const sortedFlattendTreeProducts = flattenTree(treedProducts).sort((a, b) => a.depth - b.depth)
				const treedProductsForSave = []
				sortedFlattendTreeProducts.forEach(product => {
					if (productsToCreate.some(productToCreate => productToCreate.uuid === product.uuid) || productsToUpdate.some(productToUpdate => productToUpdate.uuid === product.uuid)) {
						product.parentProductId = product.parentProductUuid ? sortedFlattendTreeProducts.find(parentProduct => parentProduct.uuid === product.parentProductUuid).id : null
						const productToSave = ractive.formatProductForSave(product)
						treedProductsForSave.push(productToSave)
					}
				})
				return treedProductsForSave
			},

			/**
			 * @description - function to sort all deletes of product objects
			 * @instance - Ractive Function
			 * @returns {object[]} - an array of objects to delete sorted by depth in the tree set up to save with initial id assignment handled
			 */
			sortProductsForDelete() {
				const ractive = this
				const treedProducts = ractive.get('treedProducts')
				const productsToDelete = ractive.getProductsToDelete()
				const sortedFlattendTreeProducts = flattenTree(treedProducts).sort((a, b) => a.depth - b.depth)
				const treedProductsForDelete = []
				sortedFlattendTreeProducts.forEach(product => {
					if (productsToDelete.includes(product?.id)) {
						treedProductsForDelete.push(product)
					}
				})
				return treedProductsForDelete
			},

			/**
			 * @description - A cleanup function to format a product for saving to the server
			 * @instance - Ractive Function
			 * @param {Object} product - The product to format for saving
			 * @returns {Object} - The product formatted for saving
			 */
			formatProductForSave(product) {
				const ractive = this
				const tags = ractive.get('tags')
				// get the product tags that are actives ids from the tags array uuids the product only has a tags object on it not an array of ids and find dont use map or includes keeping the old values that are also still selected
				const formattedProductTags = tags.reduce((acc, tag) => {
					if (product.tags?.some(productTag => productTag.uuid === tag.uuid)) {
						acc.push(tag.id)
					}
					return acc
				}, [])
				if (!product.id) { // New vs update
					return {
						name: product.name ?? null,
						active: product.active ?? false,
						category: product.category ?? null,
						description: product.description ?? null,
						inUseAtPlantIDs: product.inUseAtPlantIDs ?? [],
						parentProductId: product.parentProductId ?? null,
						productType: product.productType ?? null,
						fileIds: product.fileIds ?? [],
						tagIds: formattedProductTags ?? [],
						uuid: product.uuid ?? null,
						parentProductUuid: product.parentProductUuid ?? null,
					}
				} else {
					return {
						id: product.id ?? null,
						name: product.name ?? null,
						active: product.active ?? false,
						category: product.category ?? null,
						description: product.description ?? null,
						inUseAtPlantIDs: product.inUseAtPlantIDs ?? [],
						parentProductId: product.parentProductId ?? null,
						productType: product.productType ?? null,
						fileIds: product.fileIds ?? [],
						tagIds: formattedProductTags ?? [],
						uuid: product.uuid ?? null,
						parentProductUuid: product.parentProductUuid ?? null,
					}
				}
			},

			/**
			 * @description - A cleanup function to format a file for saving to the server
			 * @instance - Ractive Function
			 * @param {Object} fileToSave - The file to format for saving
			 * @returns {Object<productId: number, public: boolean, rank: number,base64String: string, fileName: string>} - The formatted file for saving in the format the server expects
			 */
			async formatFileForSave(fileToSave) {
				const ractive = this
				const productId = ractive.get('products').find(product => product.uuid === fileToSave.productUuid).id
				const fileData = await pFileReader.readAsDataURL(fileToSave.File)
				const base64String = fileData.replace(/^data:.*base64,/, '')
				const formattedFile = {
					productId,
					public: fileToSave.public ?? true,
					rank: fileToSave.rank || 1,
					base64String,
					fileName: fileToSave.name || null,
				}
				return formattedFile
			},

			/**
			 * @description - A cleanup function to format a file for detaching from a product (If the file is the last instance on the server it will be deleted)
			 * @instance - Ractive Function
			 * @param {Object<number[], string>} filesToDetach - The file to format for detaching
			 * @returns {Object<productId: number, fileIds: number>} - The formatted file for detaching in the format the mutation input expects
			 */
			formatFilesForDetach(filesIdsToDetach, productUuid) {
				const ractive = this
				const foundProductId = ractive.get('products').find(product => product.uuid === productUuid).id ?? null
				const formattedFile = {
					productId: foundProductId,
					fileIds: filesIdsToDetach ?? [],
				}
				return formattedFile
			},

			/**
			 * @description - A cleanup function to format a specification for saving to the server
			 * @instance - Ractive Function
			 * @param {Object} specification - The specification to format for saving
			 * @returns {Object} - The formatted specification for saving in the format the server expects
			 */
			formatSpecificationForSave(specification) {
				if (!specification.analysisOption.id || !specification.boundaryType || !specification.choice || !specification.constraint) {
					throw new Error('Missing required fields')
				}
				return {
					active: true,
					analysisOptionId: specification.analysisOption.id,
					boundaryType: specification.boundaryType,
					choice: specification.choice,
					constraint: specification.constraint,
					plantId: specification.plantId ?? null,
					productId: specification.productId ?? null,
					requiredAnalysisOptionId: specification.requiredAnalysisOptionId ?? null,
					requiredChoice: specification.requiredChoice ?? null,
					requiredConstraint: specification.requiredConstraint ?? null,
					severityClassId: specification.severityClass?.id ?? null,
				}
			},

			/**
			 * @description - A cleanup function to format a specification for updating on the server
			 * @instance - Ractive Function
			 * @param {Object} specification - The specification to format for updating
			 * @returns {Object} - The formatted specification for updating in the format the server expects
			 */
			formatSpecificationForUpdate(specification) {
				// Id is required for this formatter
				return {
					id: specification.id,
					...this.formatSpecificationForSave(specification),
				}
			},

			/**
			 * @description - A cleanup function to delete products from the server
			 * @instance - Ractive Function
			 * @param {[Object]} productsToDelete - The products array to be deleted
			 * @returns {Promise<undefined>}
			 */
			async deleteProductsMutation(productsToDelete) {
				const productIdsToDelete = productsToDelete.map(product => product.id)
				productsToDelete.length
					? await apiFetch(
						mediator,
						{
							query: mutations.deleteProducts,
							variables: {
								ids: productIdsToDelete,
							},
						},
						'deleteProducts',
					)
					: []
			},

			/**
			 * @description - A cleanup function to mutate the products tree before saving to the server to remove deleted products and update parent child relationships. First we reparent all the products that were children of the deleted product to the deleted products parent. Then we delete the deleted product from the products array. Then we delete the products from the server.
			 * @instance - Ractive Function
			 * @param {[Object]} productsToDelete - The products array to be deleted
			 * @returns {undefined}
			 */
			reparentProducts(productsToDelete) {
				const ractive = this

				productsToDelete.forEach(productToDelete => {
					// get an array of all children of the product to delete from indexed products
					const productsToUpdate = ractive.get('products').filter(product => product.parentProductUuid === productToDelete.uuid)
					productsToUpdate.forEach(async productToUpdate => {
						await ractive.updateProductKeypath(productToUpdate.unfilteredIndex, 'parentProductUuid', productToDelete.parentProductUuid ?? null)
						await ractive.updateProductKeypath(productToUpdate.unfilteredIndex, 'parentProductId', productToDelete.parentProductId ?? null)
					})
				})
			},

			/**
			 * @description - A function that saves the tags to the server
			 * @instance - Ractive Function
			 * @returns {Promise<undefined>} - Returns undefined if there is no error.
			 */
			async saveTagsMutation() {
				const ractive = this

				const tagsToCreate = ractive.getTagsToCreate()
				const tagsToUpdate = ractive.getTagsToUpdate()
				const tagsToDelete = ractive.getTagsToDelete()

				try {
					// need to save tags first so we can associate the new tag id with the analysis > option > rule > tag
					const newTags = tagsToCreate.length
						? await apiFetch(
							mediator,
							{
								query: mutations.createTags,
								variables: {
									input: tagsToCreate,
								},
							},
							'createEntityTags',
						)
						: []
					await Promise.all([
						tagsToUpdate.length
							? apiFetch(
								mediator,
								{
									query: mutations.updateTags,
									variables: {
										input: tagsToUpdate,
									},
								},
								'updateEntityTags',
							)
							: [],
						tagsToDelete.length
							? apiFetch(
								mediator,
								{
									query: mutations.deleteTags,
									variables: {
										ids: tagsToDelete,
									},
								},
								'deleteEntityTags',
							)
							: [],
					])
					if (newTags.length) {
						// find and update the new tag ids
						const tags = ractive.get('tags')
						const tagsIdsToUpdate = tags.map(tag => {
							const newTag = newTags.find(newTag => newTag.name === tag.name)
							if (newTag) {
								return { ...tag, id: newTag.id }
							}
							return tag
						})
						await ractive.set('tags', tagsIdsToUpdate)
					}
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving tags; Products not saved', message: err.message, time: 10 })
					return Promise.reject(err)
				}
			},

			/**
			 * @description - A function that saves the products to the server, updates the products array with the new products from the server, and updates the product ids to match the server
			 * @instance - Ractive Function
			 * @returns {Promise<undefined>} - Returns undefined if there is no error.
			 */
			async saveProductsMutation() {
				const ractive = this

				try {
					if (ractive.nameDuplicationCheck()) {
						mediator.call('showMessage', { type: 'danger', heading: 'Error saving products', message: 'Product names must be unique', time: 10 })
						return Promise.reject(new Error('Product names must be unique'))
					}
					const sortProductsForDelete = ractive.sortProductsForDelete() // This is the array of products to delete
					ractive.reparentProducts(sortProductsForDelete) // This is the function that reparents the products that are children of the deleted products

					// Reparent the products so anything marked as deleted is reparented
					// Run the save product section to save new things and update existing things
					// Then actually delete the products from the server so the server doesn't clean up the unparented things

					const productsToCreateAndUpdate = ractive.formatProductsForSave() // This is the array of products to create and update
					const originalProducts = ractive.get('products') // This is the array of products before any changes were made
					let remainingProductsToCreateAndUpdate = klona(productsToCreateAndUpdate) // This is the array of products to create and update that will be reduced as we save them to the server
					for (let i = 0; i < productsToCreateAndUpdate.length; i++) {
						// This is the loop that saves the products to the server and updates the products array with the new products from the server
						const product = remainingProductsToCreateAndUpdate.find(product => product.uuid === productsToCreateAndUpdate[i].uuid) // This is the product to save to the server needs formatting here
						const { uuid, parentProductUuid, ...saveableProduct } = product
						const savedProduct = await ractive.saveProduct(saveableProduct) // This is the product returned from the server after saving
						savedProduct.uuid = uuid
						savedProduct.parentProductUuid = parentProductUuid
						const originalProduct = originalProducts.find(originalProduct => originalProduct.uuid === savedProduct.uuid) // This is the original product from the products array before any changes were made
						savedProduct.productFiles = originalProduct.productFiles
						savedProduct.depth = originalProduct.depth
						savedProduct.children = originalProduct.children
						// We need to reassign some props that were lost in the save process
						for (let j = 0; j < remainingProductsToCreateAndUpdate.length; j++) {
							if (remainingProductsToCreateAndUpdate[j].parentProductUuid === savedProduct.uuid) {
								remainingProductsToCreateAndUpdate[j].parentProductId = savedProduct.id
							}
						}
						// We remove the saved product from the remainingProductsToCreateAndUpdate array
						remainingProductsToCreateAndUpdate = remainingProductsToCreateAndUpdate.filter(product => product.uuid !== savedProduct.uuid)
						// We update the product in the products array with the saved product
						await ractive.set(`products.${originalProduct.unfilteredIndex}`, savedProduct)
					}
					await ractive.deleteProductsMutation(sortProductsForDelete) // This is the function that deletes the products from the server
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving products', message: err.message, time: 10 })
					console.error('Error saving products', err)
					return Promise.reject(err)
				}
			},

			async saveProduct(product) {
				return await apiFetch(
					mediator,
					{
						query: mutations.createOrUpdateProduct,
						variables: {
							product,
						},
					},
					'createOrUpdateProduct',
				)
			},

			/**
			 * @description - A function that saves the files to the server
			 * @instance - Ractive Function
			 * @returns {Promise<undefined>} - Returns undefined if there is no error.
			 */
			async saveFilesMutation() {
				const ractive = this
				const { filesToAttach, filesToDetach } = ractive.getFormattedFiles()

				try {
					const formattedFilesToDetach = []
					filesToDetach.forEach((fileIds, productUuid) => {
						formattedFilesToDetach.push(ractive.formatFilesForDetach(fileIds, productUuid))
					})
					await Promise.allSettled([
						filesToAttach.length
							? filesToAttach.forEach(async file => {
								apiFetch(
									mediator,
									{
										query: mutations.attachFileToProduct,
										variables: {
											input: await ractive.formatFileForSave(file),
										},
									},
									'attachFilesToProducts',
								)
							}, 'attachFilesToProducts')
							: [],
						formattedFilesToDetach.length
							? formattedFilesToDetach.forEach(file => {
								apiFetch(
									mediator,
									{
										query: mutations.detachFilesFromProduct,
										variables: {
											productId: file.productId,
											fileIds: file.fileIds,
										},
									},
									'detachFilesFromProducts',
								)
							}, 'detachFilesFromProducts')
							: [],
					])
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving files', message: err.message, time: 10 })
					console.error('Error saving files', err)
					return Promise.reject(err)
				}
			},

			/**
			 * @description - A function that saves the specifications to the server
			 * @instance - Ractive Function
			 * @returns {Promise<undefined>} - Returns undefined if there is no error.
			 */
			async saveSpecificationsMutation() {
				const ractive = this
				const specificationsToCreate = ractive.getSpecificationsToCreate()
				const specificationsToUpdate = ractive.getSpecificationsToUpdate()
				const specificationsToDelete = ractive.getSpecificationsToDelete()

				try {
					const specificationsToCreateWithProductId = specificationsToCreate.map(specification => {
						const product = ractive.get('products').find(product => product.uuid === specification.productUuid)
						return { ...specification, productId: product.id }
					})
					const specificationsToUpdateWithProductId = specificationsToUpdate.map(specification => {
						const product = ractive.get('products').find(product => product.uuid === specification.productUuid)
						return { ...specification, productId: product.id }
					})
					const formattedSpecificationsToCreate = specificationsToCreateWithProductId ? specificationsToCreateWithProductId.map(specification => ractive.formatSpecificationForSave(specification)) : []
					const formattedSpecificationsToUpdate = specificationsToUpdateWithProductId
						? specificationsToUpdateWithProductId.map(specification => ractive.formatSpecificationForUpdate(specification))
						: []
					const formattedSpecificationsToDelete = specificationsToDelete ? specificationsToDelete.map(specification => specification) : []

					await Promise.all([
						formattedSpecificationsToCreate.length
							? apiFetch(
								mediator,
								{
									query: mutations.createAnalysisOptionChoices,
									variables: {
										analysisOptionChoices: formattedSpecificationsToCreate,
									},
								},
								'createAnalysisOptionChoices',
							)
							: [],
						formattedSpecificationsToUpdate.length
							? apiFetch(
								mediator,
								{
									query: mutations.updateAnalysisOptionChoices,
									variables: {
										analysisOptionChoices: formattedSpecificationsToUpdate,
									},
								},
								'updateAnalysisOptionChoices',
							)
							: [],
						formattedSpecificationsToDelete.length
							? apiFetch(
								mediator,
								{
									query: mutations.deleteAnalysisOptionChoices,
									variables: {
										ids: formattedSpecificationsToDelete,
									},
								},
								'deleteAnalysisOptionChoices',
							)
							: [],
					])
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving specifications', message: err.message, time: 10 })
					console.error('Error saving specifications', err)
					return Promise.reject(err)
				}
			},

			/**
			 * @description - A function that saves the card visibility to the server
			 * @instance - Ractive Function
			 * @returns {undefined} - Returns undefined if there is no error.
			 */
			async updateCardVisibility() {
				const ractive = this

				const detailsCardShown = ractive.get('detailsCardShown')
				const tagsCardShown = ractive.get('tagsCardShown')
				const specificationsCardShown = ractive.get('specificationsCardShown')
				const attachmentsCardShown = ractive.get('attachmentsCardShown')

				try {
					await Promise.all([
						apiFetch(mediator, {
							query: mutations.setUserSetting,
							variables: {
								value: {
									category: 'Product Management',
									name: 'Show details card',
									settingType: 'INTERFACE_HISTORY',
									newValue: detailsCardShown.toString(),
								},
							},
						}),
						await apiFetch(mediator, {
							query: mutations.setUserSetting,
							variables: {
								value: {
									category: 'Product Management',
									name: 'Show tags card',
									settingType: 'INTERFACE_HISTORY',
									newValue: tagsCardShown.toString(),
								},
							},
						}),
						await apiFetch(mediator, {
							query: mutations.setUserSetting,
							variables: {
								value: {
									category: 'Product Management',
									name: 'Show specifications card',
									settingType: 'INTERFACE_HISTORY',
									newValue: specificationsCardShown.toString(),
								},
							},
						}),
						await apiFetch(mediator, {
							query: mutations.setUserSetting,
							variables: {
								value: {
									category: 'Product Management',
									name: 'Show attachments card',
									settingType: 'INTERFACE_HISTORY',
									newValue: attachmentsCardShown.toString(),
								},
							},
						}),
					])
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving card visibility', message: err.message, time: 10 })
					console.error('Error saving card visibility', err)
					// Does not return a promise because we don't want to block the save
				}
			},

			/**
			 * @description - A function that saves current screen state to the server - save order tag > products > files > specifications
			 * @instance - Ractive Function
			 * @returns {Boolean} - True if the state got saved to the server (State reloads after save)
			 */
			async saveProducts() {
				const ractive = this

				if (!ractive.hasUnsavedChanges()) {
					mediator.call('showMessage', { type: 'info', heading: 'No changes to save', message: '', time: 10 })
					return false
				}
				if (!ractive.areFieldsValidForSave()) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving products', message: 'Please ensure all required fields are filled out', time: 10 })
					return false
				}
				mediator.call('showMessage', { heading: 'Saving...', message: '', type: 'info', time: false })
				await ractive.saveTagsMutation()
				await ractive.saveProductsMutation()
				await ractive.saveFilesMutation()
				await ractive.saveSpecificationsMutation()
				await ractive.updateCardVisibility()

				mediator.call('showMessage', { type: 'success', heading: 'Saved!', message: 'Products saved successfully.', time: 10 })
				ractive.set('productsSaved', true)
				const selectedProductId = ractive.get('selectedProductId')

				stateRouter.go(null, { lastSavedTime: Date.now(), selectedProductId }, { inherit: true, replace: false })
				return true
			},

			/**
			 * @description - A function to check if there are unsaved changes
			 * @instance - Ractive Function
			 * @returns {Boolean} - True if there are unsaved changes
			 */
			hasUnsavedChanges() {
				return this.get('hasUnsavedChanges')
			},

			/**
			 * @description - A function to check if there are any bad fields
			 * @instance - Ractive Function
			 * @returns {Boolean} - True if there are no bad fields
			 */
			areFieldsValidForSave() {
				const ractive = this
				const productsToCreateAndUpdate = [ ...ractive.getProductsToCreate(), ...ractive.getProductsToUpdate() ]
				const tagsToCreateAndUpdate = [ ...ractive.getTagsToCreate(), ...ractive.getTagsToUpdate() ]
				const specificationsToCreateAndUpdate = [ ...ractive.getSpecificationsToCreate(), ...ractive.getSpecificationsToUpdate() ]
				const productsToCreateAndUpdateValid = productsToCreateAndUpdate.every(product => product.name !== null && product.name !== '' && !ractive.nameDuplicationCheck())
				const tagsToCreateAndUpdateValid = tagsToCreateAndUpdate.every(tag => tag.name !== null && tag.name !== '')
				const specificationsToCreateAndUpdateValid = specificationsToCreateAndUpdate.every(
					specification => specification.analysisOption.analysis.id !== null && specification.analysisOption.id !== null && specification.choice !== null && specification.choice !== '',
				)
				return productsToCreateAndUpdateValid && tagsToCreateAndUpdateValid && specificationsToCreateAndUpdateValid
			},
		},
		async resolve(_data, { plantId, showInactive, showUnused, selectedProductId, selectedProductTypeFilter }) {
			plantId = parseInt(plantId, 10) || null
			selectedProductId = (!selectedProductId || (selectedProductId === 'null')) ? null : parseInt(selectedProductId, 10)
			showInactive = stringToBoolean(showInactive) ?? false
			showUnused = stringToBoolean(showUnused) ?? false
			const session = getSession()

			const productManagmentSettings = await pProps({
				savedShowInactive: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show inactive products',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'false',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
				savedShowUnused: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show unused products',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'false',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
				detailsCardShown: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show details card',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'true',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
				tagsCardShown: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show tags card',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'true',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
				specificationsCardShown: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show specifications card',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'true',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
				attachmentsCardShown: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Product Management',
								name: 'Show attachments card',
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'true',
							},
						},
					},
					'getCurrentUserSetting.value',
				),
			})

			showInactive = stringToBoolean(productManagmentSettings.savedShowInactive) ?? false
			showUnused = stringToBoolean(productManagmentSettings.savedShowUnused) ?? false
			const detailsCardShown = stringToBoolean(productManagmentSettings.detailsCardShown) ?? false
			const tagsCardShown = stringToBoolean(productManagmentSettings.tagsCardShown) ?? false
			const specificationsCardShown = stringToBoolean(productManagmentSettings.specificationsCardShown) ?? false
			const attachmentsCardShown = stringToBoolean(productManagmentSettings.attachmentsCardShown) ?? false

			const {
				//Starting a Pprops thing so we can do all the api calls at once as we add them
				plants,
				tags,
				severityClasses,
				categories,
				products,
				analysisOptions,
				analysis,
			} = await pProps({
				plants: apiFetch(
					mediator,
					{
						query: queries.plants,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'plants.data',
				),
				tags: apiFetch(
					mediator,
					{
						query: queries.tags,
						variables: {
							filter: {
								active: true,
								entityTypes: 'PRODUCT',
							},
						},
					},
					'entityTags',
				),
				severityClasses: apiFetch(
					mediator,
					{
						query: queries.severityClasses,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'severityClasses.data',
				),
				categories: apiFetch(
					mediator,
					{
						query: queries.categories,
					},
					'productCategories',
				),
				products: apiFetch(
					mediator,
					{
						query: queries.products,
						variables: {
							filter: {
								plantIdsFilteringByChildren: selectedProductTypeFilter === 'PRODUCT' ? ((showUnused || !plantId) ? null : [ plantId ]) : null,
								productType: selectedProductTypeFilter,
								activeOnly: selectedProductTypeFilter === 'PRODUCT' ? !showInactive : null,
								active: selectedProductTypeFilter === 'INGREDIENT' ? !showInactive : null,
								plantIds: selectedProductTypeFilter === 'INGREDIENT' ? ((showUnused || !plantId) ? null : [ plantId ]) : null,
							},
							orderBy: 'NAME_ASC',
							...gqlPagninationAllPages,
						},
					},
					'products.data',
				),
				analysisOptions: apiFetch(
					mediator,
					{
						query: queries.analysisOptions,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'analysisOptions.data',
				),
				analysis: apiFetch(
					mediator,
					{
						query: queries.analysis,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'analyses.data',
				),
			})
			selectedProductId = selectedProductId ?? products.filter(product => product.parentProductId === null)[0]?.id ?? null // If there is no selected product id, set it to the first product in the list

			const selectableAnalyses = analysis.filter(analysis => analysis?.inUseAtPlantIDs.includes(plantId))

			const mappedTags = tags.map(tag => ({
				...tag,
				uuid: uuid(),
				originalName: tag.name,
				deleted: false,
				remove: false,
			}))

			const mappedProduct = products.map(product => ({
				...product,
				inUseAtPlantIDs: product.inUseAtPlants.map(plant => plant.id),
				inUse: product.inUseAtPlants.map(plant => plant.id).includes(plantId),
				tags: mappedTags.filter(tag => product.tags?.some(productTag => productTag.id === tag.id)),
				childrenVisible: true,
				children: [],
				dirty: false,
				uuid: uuid(),
				productFiles: product.productFiles.map(outerFile => {
					return {
						...outerFile,
						...outerFile.file,
						createdDate: new Date(outerFile.file.created),
						md5sum: outerFile.file.hash,
						uuid: uuid(),
						deleted: false,
						dirty: false,
					}
				}),
			}))

			const mappedParentProducts = mappedProduct.map(product => {
				return {
					...product,
					parentProductUuid: product.parentProductId ? mappedProduct.find(mappedProd => mappedProd.id === product.parentProductId)?.uuid ?? null : null,
				}
			})

			// no sense getting this from the state router if it's going to be different on every load of this state
			selectedProductId = mappedParentProducts.find(product => product.id === selectedProductId)?.id ?? mappedParentProducts[0]?.id
			const selectedProductUuid = mappedParentProducts.find(product => product.id === selectedProductId)?.uuid ?? mappedParentProducts[0]?.uuid

			const productTableColumns = [
				{ property: 'name', name: 'Name', columnMinWidth: '200px', columnMaxWidth: '400px', title: 'A unique name describing a product provided' },
				{ property: 'active', name: 'Active', columnWidth: '1rem', title: 'Whether this product is active' },
				{ property: 'inUse', name: 'In Use', columnWidth: '1rem', title: 'Whether this product is in use at the current plant' },
				{
					property: 'dirty',
					icon: 'fas fa-fw fa-save',
					columnWidth: '1rem',
					title: 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
				},
			]

			const specificationColumns = [
				{
					property: 'analysisName',
					name: 'Analysis',
					columnMinWidth: '100px',
					columnMaxWidth: '200px',
					title: 'A unique name describing a test (called an analysis) to be performed',
				},
				{ property: 'analysisOption', name: 'Option', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'A unique name describing an option for an analysis' },
				{ property: 'plant', name: 'Plant', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The plant where this analysis is performed' },
				{ property: 'threshold', name: 'Threshold', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The threshold for this analysis' },
				{ property: 'severity', name: 'Severity', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The severity for this analysis' },
				{ property: 'constraint', name: 'Constraint', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The constraint for this analysis' },
				{
					property: 'choiceBoundryValue',
					name: 'Choice/Boundry Value',
					columnMinWidth: '100px',
					columnMaxWidth: '200px',
					title: 'The choice boundry value for this analysis',
				},
				{ property: 'requiredOption', name: 'Required Option', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The required option for this analysis' },
				{
					property: 'requiredConstraint',
					name: 'Required Constraint',
					columnMinWidth: '100px',
					columnMaxWidth: '200px',
					title: 'The required constraint for this analysis',
				},
				{ property: 'requiredChoice', name: 'Required Choice', columnMinWidth: '100px', columnMaxWidth: '200px', title: 'The required choice for this analysis' },
				{ property: 'delete', icon: 'fas fa-fw fa-save', columnWidth: '1rem', title: 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button' },
			]

			const { constraintsMap, thresholdsWhenMap } = buildTranslatedConstants(i18next.t)

			const productTypes = [
				{ label: 'Show Product', value: 'PRODUCT' },
				{ label: 'Show Ingredient', value: 'INGREDIENT' },
			]

			const groupedAnalysis = groupItemsByObjectProperty(analysis, 'id')

			const authorizedPlantIDs = session.authorizedPlantIDs ?? []

			// This is the list of plants that are not private or the plant the user is logged into if it is private
			const nonPrivatePlants = plants.reduce((acc, plant) => {
				if (!plant.private) {
					acc.push(plant)
				}
				const loggedInPlant = session.siteId ?? null
				if (loggedInPlant === plant.id && plant.private) {
					acc.push(plant)
				}
				return acc
			}, [])

			return {
				// Start Data
				plantId,
				authorizedPlantIDs,
				products: mappedParentProducts,
				availableSpecifications: [],
				specificationsLoading: true,
				errorLoadingSpecifications: false,
				loadedProductSpecifications: new Set(), // which products we've loaded specifications for?
				plants,
				nonPrivatePlants,
				showInactive,
				showUnused,
				categories,
				tags: mappedTags,
				analysisOptions,
				analysis,
				selectableAnalyses,
				groupedAnalysis,
				// End Data
				// Start Selected Data
				selectedProductId,
				selectedProductUuid,
				selectedTagId: null,
				selectedTagUuid: null,
				selectedSpecificationId: null,
				selectedSpecificationUuid: null,
				copiedProductList: null,
				cutProduct: null,
				// End Selected Data
				// Start Types and maps
				productTypes,
				constraintsMap,
				severityClasses,
				thresholdsMap,
				thresholdsWhenMap,
				// End Types and maps
				// Start Filter Data
				productTableFilterProps: productTableColumns.slice(0, productTableColumns.length - 1).map(col => col.property),
				selectedProductTypeFilter,
				// End Filter Data
				// Start Sort Data
				lazySortProduct: true,
				productSortColumn: productTableColumns[0],
				productSortDirection: 'ASC',
				// End Sort Data
				// Start Columns
				productTableColumns,
				specificationColumns,
				// End Columns
				imageServerUrl: FILE_BASE_URL,
				tagsCardShown,
				specificationsCardShown,
				detailsCardShown,
				attachmentsCardShown,
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			async function loadSpecifications(productId) {
				if (productId) {
					try {
						ractive.set('specificationsLoading', true)
						const loadedProductSpecifications = ractive.get('loadedProductSpecifications')
						if (loadedProductSpecifications.has(productId)) {
							return ractive.set('specificationsLoading', false)
						}
						loadedProductSpecifications.add(productId)
						const productSpecifications = await apiFetch(
							mediator,
							{
								query: queries.analysisOptionsChoices,
								variables: {
									...gqlPagninationAllPages,
									filter: {
										plantId: ractive.get('plantId'),
										productIds: [ productId ],
									},
								},
							},
							'analysisOptionChoices.data',
						)
						const products = ractive.get('products')
						const mappedSpecifications = productSpecifications.map(specification => ({
							...specification,
							productUuid: products.find(product => product.id === specification.productId)?.uuid ?? null,
							dirty: false,
							uuid: uuid(),
						}))
						await ractive.push('availableSpecifications', ...mappedSpecifications)
						await ractive.set({
							specificationsLoading: false,
							loadedProductSpecifications,
						})
					} catch (err) {
						mediator.call('showMessage', { type: 'danger', heading: i18next.t('products.errorLoadingSpecifications', 'Error Loading Specifications'), message: err.message, time: 10 })
						console.error('Error loading specifications', err)
						ractive.set({
							errorLoadingSpecifications: true,
							loadingSpecifications: false,
						})
					}
				}
			}

			loadSpecifications(ractive.get('selectedProductId'))

			ractive.observe(
				'products.*.productFiles',
				() => {
					const productIndex = ractive.get('selectedProductIndex')
					ractive.set({ [`products.${productIndex}.dirty`]: !!ractive.get(`products.${productIndex}.id`) || ractive.get(`products.${productIndex}.dirty`) })
				},
				{ init: false },
			)

			ractive.observe(
				'products.*.tags',
				() => {
					const productIndex = ractive.get('selectedProductIndex')
					ractive.set({ [`products.${productIndex}.dirty`]: !!ractive.get(`products.${productIndex}.id`) || ractive.get(`products.${productIndex}.dirty`) })
				},
				{ init: false },
			)

			ractive.on('selected-product-changed', (context, selectedProductUuid) => {
				if (ractive.get('selectedProductUuid') === selectedProductUuid) {
					ractive.unselectProduct()
				} else {
					const productId = ractive.get('products').find(product => product.uuid === selectedProductUuid)?.id ?? null
					ractive.set({
						selectedProductId: productId ?? null,
						selectedProductUuid: selectedProductUuid ?? null,
					}).then(() => {
						loadSpecifications(productId)
					})
				}
			})

			ractive.on('selected-analysis-option-changed', (context, selectedSpecificationUuid) => {
				ractive.set({
					selectedSpecificationUuid: selectedSpecificationUuid ?? null,
				})
			})

			ractive.on('add-product', (context, familyLocation) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to add products', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to add products')
					return
				}
				const products = ractive.get('products') ?? []
				// I don't this will ever happen, but just in case
				const currentSelectedProduct = ractive.get('selectedProduct')
				const newProductIndex = products.length
				const newProduct = {
					...klona(productTemplate),
					productType: ractive.get('selectedProductTypeFilter'),
					inUseAtPlantIDs: [ ractive.get('plantId') ],
					inUse: true,
					uuid: uuid(),
				}
				switch (familyLocation) {
					case 'ancestor':
						//Makes a new top level product
						break
					case 'sibling':
						//Set the parent of new Product to the selected product parent
						newProduct.parentProductUuid = currentSelectedProduct.parentProductUuid
						break
					case 'child':
						//Set the new product parent to the selected product uuid
						newProduct.parentProductUuid = currentSelectedProduct.uuid
						break
					case 'parent':
						//Set the parent to the selected product
						newProduct.parentProductUuid = currentSelectedProduct.parentProductUuid
						ractive.updateProductKeypath(currentSelectedProduct.unfilteredIndex, 'parentProductUuid', newProduct.uuid)
						break
					default:
						//Makes a new top level product
						break
				}
				ractive.push('products', {
					...newProduct,
				})

				ractive.set('detailsCardShown', true)
				ractive.fire('selected-product-changed', newProduct.uuid)
				ractive.find(`#product-name-${newProductIndex}`, { remote: true })?.focus()
			})
			ractive.on('copy-product', (context, product) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to copy products', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to copy products')
					return
				}
				// First we copy the product into a list of products
				ractive.set('cutProduct', null) //Clear the cut product if there is one
				const copiedProductList = [ klona(product) ]
				//Second we flatten the list of products to make more managable
				const flatDeepByKey = (data, key) => {
					return data.reduce((prev, el) => {
						prev.push(el)
						if (el[key]) {
							prev.push(...flatDeepByKey(el[key], key))
						}
						return prev
					}, [])
				}
				//Next we sanitize them to remove all the old ids and uuids and properties that we don't copy
				const sanitizeProductList = productList => {
					//First we remap the old uuids so we can use them to reparent the products
					const oldProductList = productList.map(product => {
						return {
							...product,
							oldUuid: product.uuid,
							oldParentProductUuid: product.parentProductUuid,
						}
					})
					//Next we sanitize the products add any more fields that we need to remove here
					const updatedProductList = oldProductList.map(product => {
						const cleanProduct = klona(productTemplate)
						const sanitizedProduct = {
							...cleanProduct,
							active: product.active,
							name: product.name,
							productType: product.productType,
							category: product.category,
							inUseAtPlantIDs: product.inUseAtPlantIDs,
							oldParentProductUuid: product.oldParentProductUuid,
							oldUuid: product.oldUuid,
						}
						return sanitizedProduct
					})
					return updatedProductList
				}
				const flattendProductList = flatDeepByKey(copiedProductList, 'children')
				const sanitizedProductList = sanitizeProductList(flattendProductList)
				//Finally we set the copied product list
				ractive.set('copiedProductList', sanitizedProductList)
			})

			ractive.on('paste-product', (context, familyLocation) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to paste products here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to paste products here')
					return
				}

				const reparentList = productList => {
					const preReparentedProductList = productList.map(product => {
						return {
							...product,
							uuid: uuid(),
							inUse: true,
						}
					})
					const reparentedList = preReparentedProductList.map(product => {
						const parentProductUuid = preReparentedProductList.find(oldProduct => oldProduct.oldUuid === product.oldParentProductUuid)?.uuid ?? null
						return {
							...product,
							parentProductUuid,
						}
					})
					return reparentedList
				}

				const currentSelectedProduct = ractive.get('selectedProduct') // First we get the current selected product to determine where to paste the new products
				const cutProduct = ractive.get('cutProduct') // Next we get the cut product if there is one
				if (cutProduct) {
					ractive.updateProductKeypath(cutProduct.unfilteredIndex, 'parentProductId', null)
					ractive.updateProductKeypath(cutProduct.unfilteredIndex, 'parentProductUuid', currentSelectedProduct.uuid)
					ractive.set('cutProduct', null)
				} else {
					const copiedProductList = ractive.get('copiedProductList') // Next we get the copied product list
					const reparentedProductList = reparentList(copiedProductList) // Then we reparent the copied product list
					const newProductList = reparentedProductList.map(product => {
						// Finally we add the new products to the products list
						const newProduct = {
							...klona(product),
							inUseAtPlantIDs: [ ractive.get('plantId') ],
							productType: ractive.get('selectedProductTypeFilter'),
							inUse: true,
						}
						switch (
							familyLocation // This is where we determine where to paste the new products
						) {
							case 'ancestor':
								//Makes a new top level product
								break
							case 'child':
								//Set the new product parent to the selected product uuid
								if (product.parentProductUuid === null) {
									newProduct.parentProductUuid = currentSelectedProduct.uuid
								}
								break
							default:
								//Makes a new top level product
								break
						}
						return newProduct // Return the new product
					})
					ractive.push('products', ...newProductList) // Push the new products to the products list
					ractive.fire('selected-product-changed', context, newProductList[0].uuid)
				}
			})

			ractive.on('delete-product', (context, index, id, type) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to delete products here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to delete products here')
					return
				}
				switch (type) {
					case 'onlyParent':
						if (
							id &&
							!confirm(
								'Are you sure you want to delete this product? \r\n\r\nIt will be removed from all schedules and work orders, and all samples that use it will be deleted. \r\n\r\nAfter saving, this action cannot be undone.',
							)
						) {
							break
						}
						ractive.set(`products.${index}.deleted`, true) //Reparenting will be handled by the API so we can 'undelete' client side
						break
					case 'alsoChildren':
						if (
							id &&
							!confirm(
								'Are you sure you want to delete this product and all of its children? \r\n\r\nThey will be removed from all schedules and work orders, and all samples that use them will be deleted. \r\n\r\nAfter saving, this action cannot be undone.',
							)
						) {
							break
						}
						ractive.set(`products.${index}.deleted`, true) //Reparenting will be handled by the API so we can 'undelete' client side
						ractive.fire('delete-product-children', context, index)
						break
					default:
						//Do nothing if the type is not recognized
						break
				}
			})

			ractive.on('delete-product-children', (context, index) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to delete products here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to delete products here')
					return
				}
				const product = ractive.get(`products.${index}`)
				if (product.children.length > 0) {
					product.children.forEach(child => {
						const childIndex = ractive.get('products').findIndex(product => product.uuid === child.uuid)
						ractive.set(`products.${childIndex}.deleted`, true)
						ractive.fire('delete-product-children', context, childIndex)
					})
				}
			})

			ractive.on('undo-delete-product', (context, index) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to undo delete products here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to undo delete products here')
					return
				}
				ractive.set(`products.${index}.deleted`, false)
			})

			ractive.on('cut-product', (context, product) => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to cut products here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to cut products here')
					return
				}
				const exactCopy = klona(product)

				const plants = ractive.get('nonPrivatePlants')
				const filteredPlants = plants.filter(plant => ractive.get('authorizedPlantIDs').some(id => id === plant.id) && product?.inUseAtPlantIDs.some(id => id === plant.id))
				if (filteredPlants.length !== plants.length && product.plantId === null) {
					//If the product is an All Plants product and the user doesn't have access to all plants you can only copy it
					ractive.fire('copy-product', context, product)
					mediator.call('showMessage', { type: 'info', heading: 'Cut Product', message: 'You do not have permission to cut this product, so it has been copied instead', time: 10 })
				} else {
					ractive.fire('copy-product', context, product) //This is the sanitized version
					ractive.set('cutProduct', exactCopy) //This is the original product, not a sanitized version
				}
			})

			ractive.on('add-specification', () => {
				if (!ractive.canEditChoice(ractive.get('plantId'))) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to make specifications here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to make specifications here')
					return
				}
				const specifications = ractive.get('availableSpecifications')
				const selectedProduct = ractive.get('selectedProduct')
				const newSpecification = {
					...klona(specificationTemplate),
					analysisOption: {
						analysis: {
							id: null,
						},
					},
					productUuid: selectedProduct.uuid,
					plantId: ractive.get('selectedPlant').id,
					uuid: uuid(),
				}
				specifications.push(newSpecification)
				ractive.set(`availableSpecifications`, specifications)
			})

			ractive.on('delete-specificiation', () => {
				if (!ractive.canEditChoice(ractive.get('plantId'))) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to delete specifications here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to delete specifications here')
					return
				}
				const selectedSpecification = ractive.get('selectedSpecification')
				ractive.updateSpecificationKeypath(selectedSpecification.unfilteredIndex, 'deleted', true)
			})

			ractive.on('undo-delete-specificiation', () => {
				if (!ractive.canEditChoice(ractive.get('plantId'))) {
					mediator.call('showMessage', { type: 'danger', heading: 'Bad Permissions', message: 'You do not have permission to undo delete specifications here', time: 10 })
					console.error('Error saving specifications', 'You do not have permission to undo delete specifications here')
					return
				}
				const selectedSpecification = ractive.get('selectedSpecification')
				ractive.updateSpecificationKeypath(selectedSpecification.unfilteredIndex, 'deleted', false)
			})

			// Start Reload Region
			const { cancel: cancelPlantObserver } = ractive.observe(
				'plantId',
				plantId => {
					ractive.updateCardVisibility()
					stateRouter.go(null, { plantId }, { inherit: true, replace: true })
				},
				{ init: false },
			)

			const { cancel: cancelProductTypeObserver } = ractive.observe(
				'selectedProductTypeFilter',
				selectedProductTypeFilter => {
					ractive.updateCardVisibility()
					stateRouter.go(null, { selectedProductTypeFilter }, { inherit: true, replace: true })
				},
				{ init: false },
			)
			const { cancel: cancelInactiveProductsObserver } = ractive.observe(
				'showInactive',
				async showInactive => {
					await apiFetch(mediator, {
						query: mutations.setUserSetting,
						variables: {
							value: {
								category: 'Product Management',
								name: 'Show inactive products',
								settingType: 'INTERFACE_HISTORY',
								newValue: showInactive.toString(),
							},
						},
					})
					ractive.updateCardVisibility()
					stateRouter.go(null, { showInactive }, { inherit: true, replace: true })
				},
				{ init: false },
			)

			const { cancel: cancelInUseAtPlantsObserver } = ractive.observe(
				'showUnused',
				async showUnused => {
					await apiFetch(mediator, {
						query: mutations.setUserSetting,
						variables: {
							value: {
								category: 'Product Management',
								name: 'Show unused products',
								settingType: 'INTERFACE_HISTORY',
								newValue: showUnused.toString(),
							},
						},
					})
					ractive.updateCardVisibility()
					stateRouter.go(null, { showUnused }, { inherit: true, replace: true })
				},
				{ init: false },
			)

			ractive.get('saveResetProps').set({ save: () => ractive.saveProducts(), disabled: true })
			ractive.observe('hasUnsavedChanges', hasUnsavedChanges => {
				ractive.get('saveResetProps').set({ save: () => ractive.saveProducts(), disabled: !hasUnsavedChanges })
			})

			context.on('destroy', () => {
				cancelPlantObserver()
				cancelProductTypeObserver()
				cancelInactiveProductsObserver()
				cancelInUseAtPlantsObserver()
				ractive.get('saveResetProps').set({})
			})
			// End Reload Region
		},
		canLeaveState: ractive => {
			return !ractive || ractive.get('canLeaveState') || ractive.get('productsSaved') || confirm('You have unsaved changes. Are you sure you want to leave this page?')
		},
	})
}

//Start Data Definitions
const plantReturnData = `#graphql
	id
	name
	code
	private
`
const tagReturnData = `#graphql
	id
	name
	entityType
	active
`
const productReturnData = `#graphql
id
active
category
description
	inUseAtPlants {
		id
	}
name
parentProductId
productType
	tags {
		${tagReturnData}
	}
	productFiles {
		id
		public
		rank
		fileId
		file {
			name
			created
			updated
			type
			hash
			mimeType
			size
		}
	}
`
// End Data Definitions
// Start Queries
const queries = {
	products: `#graphql
		query Products($filter: ProductFilter, $pagination: PaginatedInput) {
			products(filter: $filter, pagination: $pagination) {
				data {
					${productReturnData}
				}
			}
		}
	`,
	plants: `#graphql
		query Plants($pagination: PaginatedInput) {
		plants(pagination: $pagination) {
			data {
				${plantReturnData}
			}
		}
		}
	`,
	categories: `#graphql
		query productCategories {
			productCategories
		}
	`,
	tags: `#graphql
		query EntityTags($filter: EntityTagFilter!) {
			entityTags(filter: $filter) {
				${tagReturnData}
			}
		}
	`,
	severityClasses: `#graphql
		query SeverityClasses($filter: SeverityClassFilter, $pagination: PaginatedInput) {
		severityClasses(filter: $filter, pagination: $pagination) {
			data {
			id
			name
			description
			default
			plantId
			}
		}
		}
	`,
	analysisOptionsChoices: `#graphql
		query AnalysisOptionChoices($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
			analysisOptionChoices(filter: $filter, pagination: $pagination) {
				data {
					id
					plantId
					productId
					choice
					constraint
					boundaryType
					requiredAnalysisOptionId
					requiredConstraint
					requiredChoice
					productBatchId
					severityClass {
						id
						name
					}
					analysisOption {
						id
						option
						valueType
						analysis {
							id
						}
					}
				}
			}
		}
	`,
	analysisOptions: `#graphql
		query AnalysisOptions($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
			analysisOptions(filter: $filter, pagination: $pagination) {
				data {
					id
					active
					analysisId
					option
					unit
					valueType
					defaultValue
					defaultType
					thresholdType
					requiredToPerform
					requiredToClose
					informational
					rank
					productId
					rules {
						id
						analysisOptionId
						active
						restriction
						outcome
						description
						created
						tags {
							${tagReturnData}
							active
						}
					}
				}
			}
		}
	`,
	analysis: `#graphql
		query Analyses($filter: AnalysisFilter, $pagination: PaginatedInput) {
			analyses(filter: $filter, pagination: $pagination) {
				data {
					id
					name
					inUseAtPlantIDs
					options {
						id
						option
					}
				}
			}
		}
	`,
	getUserSetting: `#graphql
		query GetCurrentUserSetting($lookup: SettingLookup!) {
			getCurrentUserSetting(lookup: $lookup) {
				value
			}
		}
	`,
}
// End Queries
// Start Mutations
const mutations = {
	createTags: `#graphql
		mutation CreateEntityTags($input: [NewEntityTag!]!) {
		createEntityTags(input: $input) {
				active
				entityType
				id
				name
			}
		}
	`,
	updateTags: `#graphql
		mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
			updateEntityTags(input: $input) {
				id
			}
		}
	`,
	deleteTags: `#graphql
		mutation DeleteEntityTags($ids: [PositiveInt!]!) {
			deleteEntityTags(ids: $ids)
		}
	`,
	createOrUpdateProduct: `#graphql
		mutation CreateOrUpdateProduct($product: CreateOrUpdateProduct!) {
			createOrUpdateProduct(product: $product) {
				${productReturnData}
			}
		}
	`,
	deleteProducts: `#graphql
		mutation DeleteProducts($ids: [PositiveInt!]!) {
			deleteProducts(ids: $ids)
		}
	`,
	attachFileToProduct: `#graphql
		mutation AttachFilesToProduct($input: NewProductFile!) {
			attachFilesToProduct(input: $input) {
				id
			}
		}
	`,
	detachFilesFromProduct: `#graphql
		mutation detachFilesFromProduct($productId: PositiveInt!, $fileIds: [PositiveInt!]!) {
			detachFilesFromProduct(productId: $productId, fileIds: $fileIds)
		}
	`,
	createAnalysisOptionChoices: `#graphql
		mutation CreateAnalysisOptionChoices($analysisOptionChoices: [NewAnalysisOptionChoice!]!) {
			createAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
				id
			}
		}
	`,
	updateAnalysisOptionChoices: `#graphql
		mutation UpdateAnalysisOptionChoices($analysisOptionChoices: [AnalysisOptionChoiceUpdate!]!) {
			updateAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
				id
			}
		}
	`,
	deleteAnalysisOptionChoices: `#graphql
		mutation DeleteAnalysisOptionChoices($ids: [ID!]!) {
			deleteAnalysisOptionChoice(ids: $ids)
		}
	`,
	setUserSetting: `#graphql
		mutation SetUserSetting($value: SettingChange!) {
			setUserSetting(value: $value)
		}
	`,
}
// End Mutations

/**
 * @description This function is used to flatten a tree of products into a list of products
 * @instance - Global
 * @param {object[]} productTree
 * @returns {object[]} flattenedProducts array
 */
function flattenTree(productTree) {
	const flattenedProducts = []
	const flatten = product => {
		flattenedProducts.push(product)
		if (product.children.length > 0) {
			product.children.forEach(child => {
				flatten(child)
			})
		}
	}
	productTree.forEach(product => {
		flatten(product)
	})
	return flattenedProducts
}
