<svelte:options accessors />

<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { WorkOrderData$result, DocumentStatus$options, ShowProduct$options, WoAnalyses$result, WorkOrder$result, WorkOrderTypesAndPlants$result } from '$houdini'
	import type { WritableDeep } from 'type-fest'
	import type { SettingValueStore } from 'stores/setting-value'
	import type { FavoriteReportsStore } from 'stores/favorite-reports'
	import type { EntityProxyMap } from 'utility/entity-proxy-map'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { WoDocumentStore, WorkOrder, Sample, MetaSampleAttachment, SampleValue, DisplaySample, DisplaySampleValue, WoMediatorProviders } from '../work-order'
	import type { i18n } from 'i18next'
	import type { UserLocalWritable } from 'stores/user-local-writable'
	import { minToMs } from 'utility/to-minutes'

	type Analysis = WorkOrderData$result['analyses']['data'][number]
	type Product = WorkOrderData$result['products']['data'][number]
	type Location = WorkOrderData$result['locations']['data'][number]

	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Textarea from '@isoftdata/svelte-textarea'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import ContextMenu, { DropdownItem } from '@isoftdata/svelte-context-menu'
	import ReportJobModal from '@isoftdata/svelte-report-job-modal'
	import Table, { Td, type Column, type StringKeyOfType, type IndexedRowProps } from '@isoftdata/svelte-table'
	import Dropdown from '@isoftdata/svelte-dropdown'
	import Modal from '@isoftdata/svelte-modal'
	import TableCardFooter from '@isoftdata/svelte-table-card-footer'
	import OptionInput from 'components/OptionValueInput.svelte'
	import SampleListGroup from './SampleListGroup.svelte'
	import Attention from './Attention.svelte'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import Autocomplete from '@isoftdata/svelte-autocomplete'

	import hasPermission from 'utility/has-permission'
	import session from 'stores/session'
	import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
	import { format as formatDate, subMilliseconds } from 'date-fns'
	import { toZonedTime, getTimezoneOffset } from 'date-fns-tz'
	import { getContext, onDestroy, onMount, setContext, tick } from 'svelte'
	import { GenerateCrystalReportStore, graphql, DocumentStatus } from '$houdini'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import formatDocumentStatus, { getDocumentStatusHint } from 'utility/format-document-status'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import SampleAttachments from './SampleAttachments.svelte'
	import { RestrictionTree, DocumentTree, type AnalysisOptionChoice } from './edit'
	import { getDefaultSampleValuesList } from 'utility/get-default-sample-values'
	import userLocalWritable from 'stores/user-local-writable'
	import isValidTimeZone from 'utility/is-valid-time-zone'

	export let asr: SvelteAsr

	export let workOrder: WorkOrder
	export let workOrderStatuses: Array<DocumentStatus$options>
	export let plants: WorkOrderTypesAndPlants$result['plants']['data']
	export let workOrderTypes: WorkOrderTypesAndPlants$result['workOrderTypes']['data']
	export let productBatchesMap: EntityProxyMap<WorkOrderData$result['productBatches']['data'][number]>
	export let workerGroups: WorkOrderData$result['groups']['data']
	export let analysesMap: EntityProxyMap<Analysis>
	export let productsMap: EntityProxyMap<Product>
	export let locationsMap: EntityProxyMap<Location>
	export let choicesMap: EntityProxyMap<AnalysisOptionChoice>
	export let reports: WorkOrderData$result['reports']['data']
	export let printers: WorkOrderData$result['printers']['data']
	export let analysisPrintQuantities: Record<number, { 'Work Order Tags': number; 'Work Order Testing Tags': number }>
	export let restrictionTree: RestrictionTree

	export let showOptions: SettingValueStore<boolean>
	export let showCollectionDetail: SettingValueStore<boolean>
	export let showTestingDetail: SettingValueStore<boolean>
	export let showModifiedIcons: SettingValueStore<boolean>
	export let allowShowThresholdsTable: boolean
	export let favoriteReports: FavoriteReportsStore
	export let emailSuggestions: Array<string>
	export let showWorkOrderDetail: SettingValueStore<boolean>
	export let sampleCrudStore: CrudStore<Sample, 'uuid'>
	export let sampleAttachmentCrudStore: CrudStore<MetaSampleAttachment, 'uuid'>
	export let woDocumentStore: WoDocumentStore
	export let documentTree: DocumentTree
	export let initialDocumentStatus: DocumentStatus$options
	export let initialSampleStatuses: Record<string, DocumentStatus$options>
	export let lastWorkOrderType: UserLocalWritable<number>

	setContext('showModifiedIcons', showModifiedIcons)
	setContext('sampleAttachmentCrudStore', sampleAttachmentCrudStore)
	setContext('sampleCrudStore', sampleCrudStore)
	const { t: translate } = getContext<i18n>('i18next')

	let showConfirmCopyModal = false
	let table: Table<DisplaySample>
	let sampleContextMenu: ContextMenu
	let contextMenuSample: DisplaySample | undefined = undefined
	let selectedRowIds: Array<string> = []
	const showClosedSamples = userLocalWritable($session.userAccountId, 'showClosedSamples', true)
	// actual default will be set onMount based on screen size
	const sampleViewMode = userLocalWritable<'LIST' | 'TABLE' | null>($session.userAccountId, 'lastWorkOrderViewMode', null)

	let reportJobModal: ReportJobModal
	let tableCardFooter: TableCardFooter<SampleCsvLine>
	let showAttachmentsModal = false
	// THE sample for the attachments modal
	let selectedSample: DisplaySample | null = null
	let showNewSampleModal = false
	let newSampleTemplate: {
		analysis?: Analysis
		product?: Product
		location?: Location
	} = {}
	let productImages: Record<number, Array<string>> = {}
	let locationImages: Record<number, Array<string>> = {}
	let productLocationAttachmentModal: {
		show: boolean
		isLoading: boolean
		entityType: 'PRODUCT' | 'LOCATION'
		entityId: number | null
		entityName: string
		files: Array<string>
		currentPhotoIndex: number
	} = {
		show: false,
		isLoading: false,
		entityType: 'PRODUCT',
		entityId: null,
		entityName: '',
		files: [],
		currentPhotoIndex: 0,
	}

	// #region Sample Reactive Statements - stuff that responds to changes in workOrder.samples
	function getDisplaySamples(samples: Array<Sample>): Array<DisplaySample> {
		return samples
			.filter(sample => $showClosedSamples || sample.status !== 'CLOSED')
			.map((sample): DisplaySample => {
				return {
					...sample,
					sampleValues: getDisplaySampleValues(sample.sampleValues),
				}
			})
	}

	/** Make all the sample value arrays the same length by right padding with null */
	function getDisplaySampleValues(sampleValues: Array<SampleValue>): Array<DisplaySampleValue | null> {
		if (!optionsToShow?.size) {
			return []
		}
		const displaySvIndeces = sampleValues.reduce((indexSet: Set<number>, sv, index) => {
			if (optionsToShow.has(formatOptionName(sv.analysisOption))) {
				indexSet.add(index)
			}
			return indexSet
		}, new Set<number>())

		const newDisplaySampleValues: Array<DisplaySampleValue | null> = Array.from(displaySvIndeces).map(svOgIndex => {
			return {
				...sampleValues[svOgIndex],
				svOgIndex,
			}
		})

		const padding = maxOptionCount - newDisplaySampleValues.length
		if (padding > 0) {
			newDisplaySampleValues.push(...new Array(padding).fill(null))
		}

		return newDisplaySampleValues
	}

	/** Computes almost everything that depends on workOrder.samples reactively in "one" go. */
	function computeSampleDependencies(samples: Array<Sample>): {
		hasMultiplePlants: boolean
		hasMultipleAnalyses: boolean
		closedSampleCount: number
		optionsToShow: Set<string>
		maxOptionCount: number
	} {
		// Parallelize most of the work
		const { analysisIds, closedSampleCount, optionsToShow, plantIds } = workOrder.samples.reduce(
			(acc, sample: Sample) => {
				acc.analysisIds.add(sample.analysis.id)

				if (sample.plant) {
					acc.plantIds.add(sample.plant.id)
				}

				if (sample.status === 'CLOSED') {
					acc.closedSampleCount++
				}

				if (sample.status === 'OPEN') {
					acc.allSamplesPerformed = false
				}

				sample.sampleValues.forEach(sv => {
					const restriction = restrictionTree.get({
						analysisOptionId: sv.analysisOption.id,
						plantId: sample.plant?.id ?? workOrder.plant.id,
						locationId: sample.location?.id,
						productId: sample.product?.id,
					})
					// if not hidden or inactive (unless it has a historical value) put it in the set
					if (restriction !== 'HIDDEN' && (restriction !== 'INACTIVE' || sv.result)) {
						acc.optionsToShow.add(formatOptionName(sv.analysisOption))
					}
				})

				return acc
			},
			{
				analysisIds: new Set<number>(),
				plantIds: new Set<number>(),
				closedSampleCount: 0,
				optionsToShow: new Set<string>(),
				allSamplesPerformed: true,
			},
		)
		// Now we can compute the dependent values
		const hasMultipleAnalyses = analysisIds.size > 1
		const maxOptionCount = Math.max(...samples.map(sample => sample.sampleValues.filter(sv => optionsToShow.has(formatOptionName(sv.analysisOption))).length))

		return {
			hasMultiplePlants: plantIds.size > 1,
			hasMultipleAnalyses,
			closedSampleCount,
			optionsToShow,
			maxOptionCount,
		}
	}

	let hasMultipleAnalyses = false
	let hasMultiplePlants = false
	let closedSampleCount = 0
	let optionsToShow: Set<string> = new Set()
	let optionsColumns: Array<Column> = []
	let maxOptionCount = 0
	let displaySamples: Array<DisplaySample> = []
	let timeZoneIsValid = isValidTimeZone($session.plant.timezone)
	$: {
		$restrictionTree // force recompute when either store's value changes
		$showClosedSamples
		;({
			// line break
			hasMultipleAnalyses,
			hasMultiplePlants,
			closedSampleCount,
			optionsToShow,
			maxOptionCount,
		} = computeSampleDependencies(workOrder.samples))
		// Compute after, because we need the previous values
		displaySamples = getDisplaySamples(workOrder.samples)
		optionsColumns = buildOptionsColumns(workOrder.samples)
	}
	// #endregion

	$: console.log('workOrder', workOrder)
	$: table?.setColumnVisibility(['scheduled', 'due', 'performed', 'collectedBy[fullName]'], $showCollectionDetail && workOrder.workOrderType.showSamplingDetail)
	$: table?.setColumnVisibility(['incubationBegan', 'incubationEnded', 'platesReadBy[fullName]'], $showTestingDetail && workOrder.workOrderType.showTestingDetail)
	$: table?.setColumnVisibility(['location[location]'], workOrder.workOrderType.showLocation)
	$: table?.setColumnVisibility(['location[description]'], workOrder.workOrderType.showLocationDescription)
	$: table?.setColumnVisibility(['product[name]'], workOrder.workOrderType.showProduct !== 'NONE')
	$: table?.setColumnVisibility(['plant[name]'], hasMultiplePlants)

	$: displayColumns = [
		// This comment forces prettier to break this into multiple lines
		...columns.slice(0, 3),
		buildProductColumn(workOrder.workOrderType.showProduct),
		...columns.slice(3, 8),
		...($showOptions ? optionsColumns : []),
		...columns.slice(8),
	]
	$: canEditCurrentWorkOrder = computeCanEditCurrentWorkOrder(workOrder.plant.id, workOrder.assignedToGroup?.id ?? null, workOrder.documentStatus)
	// used for canLeaveState
	export let hasUnsavedChanges = false
	$: hasUnsavedChanges = ($sampleCrudStore && sampleCrudStore.hasChanges()) || ($woDocumentStore && woDocumentStore.hasChanges()) || false
	const mediator = getContext<Mediator<WoMediatorProviders>>('mediator')
	const fileBaseUrl = getContext<string>('fileBaseUrl')
	const columns: Array<Column> = [
		// #region Sample Details
		{
			name: '',
			property: 'attn',
			align: 'center',
			icon: 'exclamation-circle',
			title: translate('workOrder.attnColumnTitle', 'Icons will be displayed here representing various alerts. Hover over an icon to see more information.'),
			sortType: false,
		},
		{
			name: translate('workOrder.analysisColumn', 'Analysis'),
			minWidth: '200px',
			property: 'analysis[name]',
			title: translate('workOrder.analysisColumnTitle', 'Which analysis is being performed'),
		},
		{
			name: translate('workOrder.plantColumn', 'Plant'),
			property: 'plant[name]',
			title: translate('workOrder.plantColumnTitle', 'The plant the analysis was performed at'),
		},
		// Product/Ingredient goes here
		{
			name: translate('workOrder.locationColumn', 'Location'),
			minWidth: '200px',
			property: 'location[location]',
			title: translate('workOrder.locationColumnTitle', 'Where the sample was collected (when appropriate)'),
		},
		{
			name: translate('workOrder.locationDescriptionColumn', 'Location Description'),
			minWidth: '200px',
			property: 'location[description]',
			title: translate('workOrder.locationDescriptionColumnTitle', 'The description of the location the sample was collected at'),
		},
		{
			name: translate('workOrder.testingCommentsColumn', 'Testing Comments'),
			property: 'testingComments',
			minWidth: '200px',
			title: translate('workOrder.testingCommentsColumnTitle', '(Optional) additional findings from the testing process'),
		},
		{
			name: translate('workOrder.samplingCommentsColumn', 'Sampling Comments'),
			property: 'samplingComments',
			minWidth: '200px',
			title: translate('workOrder.samplingCommentsColumnTitle', '(Optional) comments from sample collector'),
		},
		{
			name: translate('workOrder.statusColumn', 'Status'),
			minWidth: '125px',
			property: 'status',
			title: translate('workOrder.statusColumnTitle', 'The current status of the sample collection'),
		},
		// #endregion
		// Options go here! On the desktop, there's a user setting for where it goes, but I ain't doing that right now
		// #region Collection Details
		{
			name: translate('workOrder.scheduledColumn', 'Scheduled'),
			property: 'scheduled',
			title: translate(
				'workOrder.scheduledColumnTitle',
				'The date and time this sample is scheduled to be collected. The sample should be performed some time between when its scheduled and when its due (if applicable)',
			),
		},
		{
			name: translate('workOrder.dueColumn', 'Due'),
			property: 'due',
			title: translate('workOrder.dueColumnTitle', 'The latest this sample should be collected/performed (if applicable)'),
		},
		{
			name: translate('workOrder.performedColumn', 'Performed On'),
			property: 'performed',
			title: translate('workOrder.performedColumnTitle', 'When the sample was collected (set automatically)'),
		},
		{
			name: translate('workOrder.performedByColumn', 'Performed By'),
			property: 'collectedBy[fullName]',
			title: translate('workOrder.performedByColumnTitle', 'The user who collected the sample (set automatically)'),
		},
		// #endregion Collection Details
		{
			name: translate('workOrder.tagNumberColumn', 'Tag #'),
			property: 'tagNumber',
			minWidth: '100px',
			numeric: true,
			sortType: 'ALPHA_NUM',
			title: translate('workOrder.tagNumberColumnTitle', 'The value that uniquely identifies this sample. Leave this blank to automatically assign a value on save'),
		},
		// #region Testing Details
		{
			name: translate('workOrder.testingBeganColumn', 'Testing Began'),
			property: 'incubationBegan',
			title: translate('workOrder.testingBeganColumnTitle', 'Start of testing process (such as incubation)'),
		},
		{
			name: translate('workOrder.testingEndedColumn', 'Testing Ended'),
			property: 'incubationEnded',
			title: translate('workOrder.testingEndedColumnTitle', 'End of testing process (such as incubation)'),
		},
		{
			name: translate('workOrder.testedByColumn', 'Tested By'),
			property: 'platesReadBy[fullName]',
			title: translate('workOrder.testedByColumnTitle', 'The user who performed the testing process'),
		},
		// #endregion Testing Details
	]

	async function getDisplayProducts(plantId: number) {
		const products = await productsMap[plantId]
		return products.filter(product => product && (workOrder.workOrderType.showProduct === 'BOTH' || workOrder.workOrderType.showProduct === product.productType))
	}

	function formatOptionName({ option, unit }: { option: string; unit: string }) {
		return unit ? `${option} (${unit})` : option
	}

	function canDeleteSelectedSamples(selectedRowIds: Array<string>) {
		return selectedRowIds.every(uuid => {
			const sample = workOrder.samples.find(sample => sample.uuid === uuid)
			return !sample?.id || (hasPermission('WORK_ORDERS_CAN_DELETE_SAMPLES', sample.plant?.id ?? workOrder.plant.id) && sample.status !== 'CLOSED')
		})
	}

	function isDocumentStatus(status: string): status is DocumentStatus$options {
		return status in DocumentStatus
	}

	/** Check that a sample has all required values for the current status (Closed/Performed) */
	function sampleMissingRequiredValuesForStatus(sample: Sample, sampleStatus: DocumentStatus$options) {
		// Don't need to validate these statuses
		if (sampleStatus === 'CANCELLED' || sampleStatus === 'OPEN') {
			return []
		}

		return sample.sampleValues.filter(sv => {
			const restriction = restrictionTree.get({
				analysisOptionId: sv.analysisOption.id,
				plantId: sample.plant?.id ?? workOrder.plant.id,
				locationId: sample.location?.id,
				productId: sample.product?.id,
			})

			let required = false
			if (sampleStatus === 'CLOSED') {
				// Closing a sample also requires all required values for performing
				required ||= restriction === 'REQUIRED_TO_PERFORM' || restriction === 'REQUIRED_TO_CLOSE'
			} else if (sampleStatus === 'SAMPLED') {
				required ||= restriction === 'REQUIRED_TO_PERFORM'
			}

			return required && !sv.result
		})
	}

	function allSamplesPerformed() {
		// This hsould technically be checking if every sample is 100% filled out
		const acceptableStatuses = ['CANCELLED', 'SAMPLED', 'CLOSED']
		return workOrder.samples.every(sample => acceptableStatuses.includes(sample.status))
	}

	function allSamplesCompleted() {
		return workOrder.samples.every(sample =>
			sample.sampleValues.every(sv => {
				if (sv.result) {
					return true
				}
				const restriction = restrictionTree.get({
					analysisOptionId: sv.analysisOption.id,
					plantId: sample.plant?.id ?? workOrder.plant.id,
					locationId: sample.location?.id,
					productId: sample.product?.id,
				})
				return restriction === 'HIDDEN' || restriction === 'INACTIVE'
			}),
		)
	}

	function allSamplesClosed() {
		return workOrder.samples.every(sample => sample.status === 'CLOSED' || sample.status === 'CANCELLED')
	}

	/** Set all samples that aren't already sampled or closed to that status */
	function updateSampleStatuses(newStatus: 'CLOSED' | 'SAMPLED') {
		workOrder.samples.forEach(sample => {
			if (newStatus === 'CLOSED' && sample.status !== 'CLOSED' && sample.status !== 'CANCELLED') {
				sample.status = 'CLOSED'
				sampleCrudStore.update(sample)
			} else if (newStatus === 'SAMPLED' && sample.status !== 'CANCELLED' && sample.status !== 'CLOSED') {
				sample.status = 'SAMPLED'
				sampleCrudStore.update(sample)
			}
		})
		workOrder.samples = workOrder.samples
	}

	// If they're required, do we have them?
	function titleAndProductBatchMissing() {
		let titleMissing = workOrder.workOrderType.titleRequired && !workOrder.title
		let productBatchMissing = workOrder.workOrderType.productBatchRequired === 'REQUIRE' && !workOrder.productBatch

		return { titleMissing, productBatchMissing }
	}

	const removeRequiredValuesProvider = mediator.provide('sampleMissingRequiredValuesForStatus', sampleMissingRequiredValuesForStatus)
	const removeSamplesPerformedProvider = mediator.provide('allSamplesPerformed', allSamplesPerformed)
	const removeSamplesCompleteProvider = mediator.provide('allSamplesCompleted', allSamplesCompleted)
	const removeSamplesClosedProvider = mediator.provide('allSamplesClosed', allSamplesClosed)
	const removeUpdateSampleStatusesProvider = mediator.provide('updateSampleStatuses', updateSampleStatuses)
	const removeTitleAndProductBatchMissingProvider = mediator.provide('titleAndProductBatchMissing', titleAndProductBatchMissing)

	function computeCanEditCurrentWorkOrder(plantId: number, groupId: number | null, documentStatus: DocumentStatus$options) {
		if (initialDocumentStatus === 'CLOSED') {
			return false
		}
		if (!hasPermission('WORK_ORDERS_CAN_EDIT', plantId)) {
			return false
		}
		if (groupId && !$session.user.userGroupIds.includes(groupId) && !hasPermission('WORK_ORDERS_CAN_EDIT_OTHER_GROUPS', plantId)) {
			return false
		}
		return true
	}

	function computeCanEditSample(canEditWo: boolean, sampleStatus: DocumentStatus$options) {
		if (!canEditWo) {
			return false
		}
		if (sampleStatus === 'CLOSED') {
			return false
		}
		return true
	}

	// not in love with passing the whole sample/samplevalue objects here, it might cause it to recompute too often
	function computeCanEditSampleValue(canEditWo: boolean, sampleStatus: DocumentStatus$options, sample: DisplaySample, sampleValue: SampleValue) {
		if (!canEditWo) {
			return false
		}
		if (!computeCanEditSample(canEditWo, sampleStatus)) {
			return false
		}
		let plantId = sample.plant?.id ?? workOrder.plant.id
		let required = restrictionTree
			.get({
				analysisOptionId: sampleValue.analysisOption.id,
				plantId,
				locationId: sample.location?.id,
				productId: sample.product?.id,
			})
			.includes('REQUIRED')
		if (sampleValue.id && required && !hasPermission('WORK_ORDERS_CAN_CHANGE_REQUIRED_OPTIONS', plantId)) {
			return false
		}
		if (sampleValue.id && !required && !hasPermission('WORK_ORDERS_CAN_CHANGE_UNREQUIRED_OPTIONS', plantId)) {
			return false
		}

		if (!sampleValue.analysisOption.active && !sampleValue.result) {
			return false
		}
		return true
	}
	setContext('computeCanEditCurrentWorkOrder', computeCanEditCurrentWorkOrder)
	setContext('computeCanEditSample', computeCanEditSample)
	setContext('computeCanEditSampleValue', computeCanEditSampleValue)

	type AnalysisOptions = WoAnalyses$result['analyses']['data'][number]['options']
	type SampleValues = WorkOrder$result['workOrder']['samples'][number]['sampleValues']
	function analysisOptionsToBlankSampleValues(analysisOptions: AnalysisOptions, sampleId = 0): SampleValues {
		return (
			analysisOptions
				// Need to include inactive options that are being shown on another line so columns line up
				.filter(o => o.active || optionsToShow.has(o.option))
				.map(option => {
					return {
						id: 0,
						sampleId,
						result: '',
						resultStatus: 'NOT_CALCULATED',
						analysisOption: option,
						defaultValue: null,
						filledOut: '',
						lastModified: null,
						createdInvestigationId: null,
					}
				})
		)
	}

	function buildProductColumn(showProduct: ShowProduct$options) {
		const productColumn = {
			// Name depends on workorder type - either, both, or none
			name: translate('workOrder.productIngredientColumn', 'Product / Ingredient'),
			minWidth: '200px',
			property: 'product[name]',
			title: translate('workOrder.productColumnTitle', '(Optional) the product or ingredient this sample relates to'),
		}

		if (showProduct === 'PRODUCT') {
			productColumn.name = translate('workOrder.productColumn', 'Product')
		} else if (showProduct === 'INGREDIENT') {
			productColumn.name = translate('workOrder.ingredientColumn', 'Ingredient')
		}

		return productColumn
	}
	function buildOptionsColumns(samples: Array<Sample>): Array<Column> {
		if (!samples.length) {
			return []
		}

		if (hasMultipleAnalyses) {
			return new Array(maxOptionCount).fill(null).map((_, svIndex) => ({
				name: translate('workOrder.optionColumn', 'Option {{number}}', { number: svIndex + 1 }),
				property: `sampleValues[${svIndex}][result]`,
				minWidth: '200px',
			}))
		}

		// One analysis, so we can just use the sampleValues directly
		const arr = new Array(maxOptionCount)
			.fill(null)
			.map((_, svIndex) => {
				return {
					name: samples[0].sampleValues[svIndex].analysisOption.unit
						? `${samples[0].sampleValues[svIndex].analysisOption.option} (${samples[0].sampleValues[svIndex].analysisOption.unit})`
						: samples[0].sampleValues[svIndex].analysisOption.option,
					property: `sampleValues[${svIndex}][result]`,
					minWidth: '200px',
				}
			})
			.filter(column => optionsToShow.has(column.name))
		return arr
	}

	interface SampleCsvLine extends IndexedRowProps {
		Analysis: string
		Product: string
		Location: string
		'Location Description': string
		'Testing Comments': string
		'Sampling Comments': string
		Status: string
		Scheduled: string
		Due: string
		'Performed On': string
		'Performed By': string
		'Tag #': string
		'Testing Began': string
		'Testing Ended': string
		'Tested By': string
		originalIndex: number
		uuid: string
	}

	function buildCsvItems(samples: Array<Sample>) {
		const maxOptionCount = hasMultipleAnalyses ? Math.max(...samples.map(sample => sample.sampleValues.length)) : (samples[0]?.sampleValues.length ?? 0)

		function buildCsvOptions(sample: Sample) {
			const options = {}
			if (hasMultipleAnalyses) {
				for (let index = 0; index < Math.min(maxOptionCount, sample.sampleValues.length); index++) {
					options[`Option ${index + 1}`] = sample?.sampleValues[index]?.result
				}
			} else {
				for (let index = 0; index < Math.min(maxOptionCount, sample.sampleValues.length); index++) {
					const analysisOption = sample?.sampleValues[index].analysisOption.option
					options[analysisOption] = sample?.sampleValues[index].result
				}
			}
			return options
		}

		return samples.map((sample, originalIndex): SampleCsvLine => {
			// This is more or less the format the desktop exports in
			return {
				Analysis: sample.analysis.name,
				Product: sample.product?.name ?? '',
				Location: sample.location?.location ?? '',
				'Location Description': sample.location?.description ?? '',
				'Testing Comments': sample.testingComments,
				'Sampling Comments': sample.samplingComments,
				Status: sample.status,
				...buildCsvOptions(sample),
				Scheduled: sample.scheduled ?? '',
				Due: sample.due ?? '',
				'Performed On': sample.performed ?? '',
				'Performed By': sample.collectedBy?.fullName ?? '',
				'Tag #': sample.tagNumber ?? '',
				'Testing Began': sample.incubationBegan ?? '',
				'Testing Ended': sample.incubationEnded ?? '',
				'Tested By': sample.platesReadBy?.fullName ?? '',
				// These aren't needed for the CSV, but we need them for it to work
				originalIndex,
				uuid: sample.uuid,
			}
		})
	}

	function onVerifiedChange(event: Event) {
		if (event.target instanceof HTMLInputElement && event.target.checked) {
			workOrder.verifiedByUser = {
				id: $session.userAccountId,
				fullName: $session.user.fullName ?? $session.userName,
			}
			workOrder.verifiedOn = new Date().toISOString()
		} else {
			workOrder.verifiedByUser = null
			workOrder.verifiedOn = null
		}
		woDocumentStore.setFromDocument(workOrder)
	}

	function formatDateTimeForInput(date: string | null): string {
		if (!date) {
			return ''
		} else if (!timeZoneIsValid) {
			return formatDate(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
		}
		// Convert dates to the logged-in plant's timezone
		return formatDate(toZonedTime(new Date(date), $session.plant.timezone), "yyyy-MM-dd'T'HH:mm:ss")
	}

	/** datetime-local inputs give us dates zoned as the device's timezone,
	 * so we have to subtract the difference between the plant and device timezones
	 * in order to make it appear correct, without changing the actual timezone of the date */
	function addPlantTzOffset(userTzDate: Date) {
		if (!timeZoneIsValid) {
			return userTzDate.toISOString()
		}

		const plantUtcOffset = getTimezoneOffset($session.plant.timezone)
		const userUtcOffset = minToMs(userTzDate.getTimezoneOffset())
		const diff = plantUtcOffset + userUtcOffset
		const plantTzDate = subMilliseconds(userTzDate, diff)
		return plantTzDate.toISOString()
	}

	// input gives us a date string in the user's timezone. Convert to UTC and save on the work order
	function setWoDateFieldFromInput(event: Event, key: StringKeyOfType<WritableDeep<WorkOrder>, string | null>) {
		if (event.target instanceof HTMLInputElement) {
			const utcDateString = addPlantTzOffset(new Date(event.target.value))

			// exclude document status since TS was getting hinky with the string union type
			if (key && key !== 'documentStatus') {
				workOrder[key] = utcDateString
			}
		}
		woDocumentStore.setFromDocument(workOrder)
	}

	function setSampleDateFieldFromInput(event: Event, key: StringKeyOfType<Sample, string | null>, index: number) {
		if (event.target instanceof HTMLInputElement) {
			const userTzDate = new Date(event.target.value)
			const utcDateString = addPlantTzOffset(userTzDate)

			if (key && key !== 'status') {
				workOrder.samples[index][key] = utcDateString
			}
		}
	}

	// #region Cloning
	const cloneWoMutation = graphql(`
		mutation CopyWorkOrders($input: [CopyWorkOrderInput!]!) {
			copyWorkOrders(input: $input) {
				lastClonedId
			}
		}
	`)

	async function cloneWo(copyValues: boolean = false) {
		try {
			const res = await cloneWoMutation.mutate({
				input: [
					{
						id: workOrder.id,
						copyValues,
						copyMostRecent: false,
					},
				],
			})

			const workOrderId = res.data?.copyWorkOrders?.[0]?.lastClonedId

			if (!workOrderId) {
				throw new Error(translate('workOrder.serverDidNotReturnWoIdError', 'The server did not return a WO #.'))
			}
			asr.go(null, { workOrderId }, { inherit: false })
		} catch (err) {
			console.error(err)
			mediator.call('showMessage', {
				type: 'danger',
				heading: translate('workOrder.failedToCopyWoErrorHeading', 'Failed to Copy Work Order'),
				message: err instanceof Error ? err.message : translate('workOrder.unknownError', 'An unknown error occurred.'),
				time: false,
			})
		}
	}
	//#endregion Cloning
	/** If the Work Order has beeen Verified, we need to tell the user that certain changes will require it be re-verified.
	 * @returns {boolean} - True if the operation should continue, false if it should be cancelled.
	 */
	function confirmActionThatRequiresReVerify() {
		const verified = !!workOrder.verifiedOn

		if (!verified) {
			return true
		}

		const confirmed = confirm(
			translate(
				'workOrder.confirmReVerificationAlert',
				`
Re-Verification Required.

This change will require that the work order be verified again.
Are you sure you want to continue?
`,
			),
		)
		if (confirmed) {
			workOrder.verifiedByUser = null
			workOrder.verifiedOn = null
			woDocumentStore.setFromDocument(workOrder)
			return true
		}
		return false
	}
	setContext('confirmActionThatRequiresReVerify', confirmActionThatRequiresReVerify)

	function newSampleClick() {
		// Just create the sample if they're in "table" view, skipping the modal
		if ($sampleViewMode === 'TABLE') {
			if (!confirmActionThatRequiresReVerify()) {
				return
			}

			newSample({})
		} else {
			openNewSampleModal()
		}
	}

	async function openNewSampleModal() {
		if (!confirmActionThatRequiresReVerify()) {
			return
		}

		showNewSampleModal = true
		const analyses = await analysesMap[workOrder.plant.id]
		newSampleTemplate = {
			analysis: analyses.find(analysis => analysis.id === workOrder.workOrderType.defaultAnalysisId) ?? undefined,
			product: workOrder.productBatch?.product ?? undefined,
			location: workOrder.productBatch?.location ?? undefined,
		}
		await tick()
	}

	// In mobile view, a modal will ask them to select an analysis/product/location, which is passed here as the "template"
	async function newSample(template: typeof newSampleTemplate) {
		const analyses = await analysesMap[workOrder.plant.id]
		let analysis = template.analysis ?? analyses.find(analysis => analysis.id === workOrder.workOrderType.defaultAnalysisId) ?? analyses[0]

		// TODO expand type to allow null id
		const newSample: Sample = {
			uuid: uuid(),
			id: 0,
			plant: klona(workOrder.plant),
			analysis: {
				id: analysis.id,
				name: analysis.name,
				requireAuthentication: analysis.requireAuthentication,
			},
			product: null,
			location: null,
			testingComments: '',
			samplingComments: '',
			status: 'OPEN',
			tagNumber: '',
			scheduled: null,
			due: null,
			performed: null,
			collectedBy: null,
			incubationBegan: null,
			incubationEnded: null,
			investigationId: null,
			platesReadBy: null,
			sampleValues: analysisOptionsToBlankSampleValues(analysis.options),
			attachments: [],
		}

		newSample.product = template.product ?? null
		newSample.location = template.location ?? null

		// Get default product and location from the product batch, if they didn't specify them in a template
		if (workOrder.productBatch) {
			newSample.product ??= klona(workOrder.productBatch.product)
			newSample.location ??= klona(workOrder.productBatch.location)
		}

		restrictionTree.fetchForSample(newSample, workOrder.plant.id)
		documentTree.fetchForSample(newSample, workOrder.plant.id)

		workOrder.samples.push(newSample)
		sampleCrudStore.create(newSample)
		workOrder.samples = workOrder.samples
		initialSampleStatuses[newSample.uuid] = newSample.status
		await tick()
		document.querySelector<HTMLSelectElement>(`#analysis-${newSample.uuid}`)?.focus()
	}
	// #region Product & Location Images
	function isLoadingProductLocationImages(isLoading: boolean, entityType: 'PRODUCT' | 'LOCATION', entityId: number) {
		return isLoading && productLocationAttachmentModal.entityType === entityType && productLocationAttachmentModal.entityId === entityId
	}

	async function showProductLocationImages(entityType: 'PRODUCT' | 'LOCATION', entityId: number, entityName: string) {
		productLocationAttachmentModal = {
			show: false,
			isLoading: true,
			entityType,
			entityId,
			entityName,
			files: [],
			currentPhotoIndex: 0,
		}
		await tick()
		const files = entityType === 'PRODUCT' ? await getProductImages(entityId) : await getLocationImages(entityId)
		// assigning stuff weird here so the right images show even if you mash the buttons
		productLocationAttachmentModal = {
			show: true,
			isLoading: false,
			entityType,
			entityId,
			entityName,
			files,
			currentPhotoIndex: 0,
		}
		await tick()
		productLocationAttachmentModal.files = files
		await tick()
	}

	async function getProductImages(productId: number) {
		if (productImages[productId]) {
			return productImages[productId]
		}
		const { data } = await getProductAttachments.fetch({ variables: { productId: productId.toString() } })

		if (!data?.product?.attachments) {
			productImages[productId] = []
			return []
		}

		const images = data.product.attachments.reduce((paths, attachment) => {
			if (attachment.file.type === 'IMAGE') {
				paths.push(`${fileBaseUrl}${attachment.file.path}`)
			}
			return paths
		}, new Array<string>())

		productImages[productId] = images
		return images
	}

	async function getLocationImages(locationId: number) {
		if (locationImages[locationId]) {
			return locationImages[locationId]
		}
		const { data } = await getLocationAttachments.fetch({ variables: { locationId: locationId.toString() } })

		if (!data?.location?.attachments) {
			locationImages[locationId] = []
			return []
		}

		const images = data.location.attachments.reduce((paths, attachment) => {
			if (attachment.file.type === 'IMAGE') {
				paths.push(`${fileBaseUrl}${attachment.file.path}`)
			}
			return paths
		}, new Array<string>())

		locationImages[locationId] = images
		return images
	}
	//#endregion Product & Location Images

	//#region Printing
	function printWorkOrder() {
		if (!workOrder.id) {
			return alert(translate('workOrder.saveWorkOrderPrintError', 'Please save the work order before printing.'))
		}
		let parameters: Record<string, string> = {
			workorderid: workOrder.id.toString(),
		}
		const reportJob = {
			type: 'Work Order',
			parameters,
		}
		reportJobModal.open(reportJob, { emails: emailSuggestions })
	}

	function printTags(type: 'Work Order Tags' | 'Work Order Testing Tags', mode: 'ALL' | 'SELECTED' | 'SINGLE') {
		let samples: Array<Sample | DisplaySample> = []
		if (mode === 'SINGLE' && contextMenuSample?.tagNumber) {
			samples = [contextMenuSample]
		} else if (mode === 'SINGLE') {
			alert(translate('workOrder.noSampleOrTagNumberPrintError', 'No sample selected, or no tag number found.'))
		} else if (mode === 'SELECTED' && selectedRowIds.length) {
			samples = selectedRowIds.map(uuid => workOrder.samples.find(sample => sample.uuid === uuid)).filter(Boolean) as Array<Sample>
		} else if (mode === 'SELECTED') {
			alert(translate('workOrder.noSamplePrintError', 'No samples selected.'))
		} else if (mode === 'ALL') {
			samples = workOrder.samples
		}
		const allSamplesLength = samples.length
		samples = samples.filter(sample => sample.tagNumber)
		if (samples.length !== allSamplesLength) {
			alert(translate('workOrder.unsavedSamplePrintError', 'Some samples do not have tag numbers and must be saved before printing tags.'))
		}

		const reportJobs = samples.map(({ tagNumber, analysis }) => {
			return {
				type,
				parameters: {
					workorderid: workOrder.id.toString(),
					tagnumber: tagNumber,
					// TODO, on the fly quantity adjustment?
					quantity: analysisPrintQuantities[analysis.id]?.[type].toString() ?? '1',
				},
			}
		})
		reportJobModal.open(reportJobs)
	}

	async function getDefaultSampleValues(sample: Sample, index: number) {
		sample.sampleValues = await getDefaultSampleValuesList(workOrder, sample)
		workOrder.samples[index] = sample
	}

	const getDefaultReportPrinter = graphql(`
		query GetDefaultReportPrinter($input: GetReportPrinter!) {
			getDefaultReportPrinter(input: $input) {
				printer
			}
		}
	`)

	const setDefaultReportPrinter = graphql(`
		mutation SetDefaultReportPrinter($input: SetReportPrinter!) {
			setDefaultReportPrinter(input: $input) {
				id
			}
		}
	`)

	const createReportQueueJob = graphql(`
		mutation CreateReportQueueJob($newReportQueue: NewReportQueue!) {
			createReportQueueJob(newReportQueue: $newReportQueue) {
				id
			}
		}
	`)

	const generateCrystalReport = new GenerateCrystalReportStore()

	async function generatePdfPreview({ name, type, parameters, id }: { id: number; name: string; type: string; parameters: Record<string, string> }) {
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id,
			},
		})
		if (!data) {
			throw new Error(translate('workOrder.generateReportNoDataError', 'No data returned from generateCrystalReport'))
		}
		return data.generateCrystalReport
	}

	const getLocationAttachments = graphql(`
		query WoLocationAttachments($locationId: ID!) {
			location(id: $locationId) {
				attachments {
					file {
						path
						type
					}
				}
			}
		}
	`)

	const getProductAttachments = graphql(`
		query WoProductAttachments($productId: ID!) {
			product(id: $productId) {
				attachments {
					file {
						path
						type
					}
				}
			}
		}
	`)
	// #endregion Printing
	onMount(() => {
		const breakpoint = getBootstrapBreakpoint()
		if (['xs', 'sm', 'md'].includes(breakpoint)) {
			$sampleViewMode = 'LIST'
		} else if (!$sampleViewMode) {
			$sampleViewMode = 'TABLE'
		}

		if (workOrder.id) {
			mediator.call('activity', {
				title: `WO# ${workOrder.id} (${workOrder.title || workOrder.workOrderType.name})`,
				type: 'WORK_ORDER',
				route: 'app.work-order.edit',
				name: translate('workOrder.recentActivityName', 'Work Order'),
				parameters: {
					workOrderId: workOrder.id.toString(),
				},
			})
		}
	})
	// If the first/only change they make on the screen is a sample or attachment, set the WO store so we can save
	const sampleCrudUnsub = sampleCrudStore.subscribe(() => {
		if ($woDocumentStore === null && sampleCrudStore.hasChanges()) {
			woDocumentStore.setFromDocument(workOrder)
		}
	})
	const sampleAttachmentCrudUnsub = sampleAttachmentCrudStore.subscribe(() => {
		if ($woDocumentStore === null && sampleAttachmentCrudStore.hasChanges()) {
			woDocumentStore.setFromDocument(workOrder)
		}
	})

	onDestroy(() => {
		sampleCrudStore.clear()
		woDocumentStore.clear()
		sampleCrudUnsub()
		sampleAttachmentCrudUnsub()
		removeRequiredValuesProvider()
		removeSamplesPerformedProvider()
		removeSamplesClosedProvider()
		removeUpdateSampleStatusesProvider()
		removeTitleAndProductBatchMissingProvider()
		removeSamplesCompleteProvider()
	})
</script>

<div class="card-body">
	{#if !timeZoneIsValid}
		<h6 class="text-danger text-center">
			Plant time zone "{$session.plant.timezone}" is not valid and should be changed. Dates may not be shown in the correct time zone. Please contact your administrator.
		</h6>
	{/if}
	<CollapsibleCard
		bind:bodyShown={$showWorkOrderDetail}
		cardClass="mb-2"
		entireHeaderToggles
		cardHeaderClass="card-header nowrap d-flex h5"
		headerText=""
	>
		<div
			slot="cardHeader"
			class="ml-sm-2 mr-auto d-flex flex-wrap"
		>
			<div
				inert
				class="mr-2"
			>
				{workOrder.id ? `WO #${workOrder.id}${workOrder.title ? ` - "${workOrder.title}"` : ''}` : 'Work Order'} Details
			</div>
			<div>
				<span
					class="badge badge-pill"
					class:badge-success={workOrder.documentStatus === 'OPEN'}
					class:badge-primary={workOrder.documentStatus === 'SAMPLED'}
					class:badge-danger={workOrder.documentStatus === 'CLOSED' || workOrder.documentStatus === 'CANCELLED'}
					title={getDocumentStatusHint(translate, workOrder.documentStatus)}>{formatDocumentStatus(translate, workOrder.documentStatus)}</span
				>
				{#if workOrder.verifiedOn}
					<span
						class="badge badge-pill badge-info"
						title={translate('workOrder.verifiedBadgeTitle', 'This work order has been inspected and verified')}>{translate('workOrder.verifiedBadge', 'Verified')}</span
					>
				{/if}
			</div>
		</div>
		<Button
			slot="headerRight"
			outline
			size="sm"
			class="mr-2"
			iconClass="search"
			title={translate('workOrder.woLookupTitle', 'Search for a work order by number')}
			on:click={event => {
				event.stopPropagation()
				const workOrderId = prompt(translate('workOrder.woLookupPrompt', 'Enter WO #:'), (workOrder.id || '')?.toString())
				if (workOrderId && workOrderId) {
					asr.go(null, { workOrderId, lastResetTime: Date.now() })
				}
			}}
		></Button>
		<div class="form-row">
			<div class="col-12 col-md-6 col-xl-2">
				<Input
					label={translate('workOrder.titleLabel', 'Title')}
					placeholder={translate('workOrder.titlePlaceholder', 'Enter a title for this work order')}
					title={translate('workOrder.titleTitle', 'An optional title or description of this document for later reference. This is used to label the WO on the home screen and other places.')}
					required={!!workOrder.workOrderType.titleRequired}
					value={workOrder.title}
					disabled={!canEditCurrentWorkOrder || !hasPermission('WORK_ORDERS_CAN_EDIT_TITLE', workOrder.plant.id)}
					validation={{
						validator: value => {
							const translated = translate('workOrder.requiredToClose', 'Required to Close')
							return !!value ? true : typeof translated === 'string' ? translated : 'Required to Close'
						},
					}}
					on:change={event => {
						if (event.target instanceof HTMLInputElement) {
							workOrder.title = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				/>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.woTypeLabel', 'WO Type')}
					title={translate('workOrder.woTypeTitle', "The work order's type can be set here. This dictates many defaults and settings for the work order.")}
					options={workOrderTypes}
					required
					disabled={!canEditCurrentWorkOrder}
					showAppend={hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', workOrder.plant.id)}
					showEmptyOption={false}
					bind:value={workOrder.workOrderType}
					on:change={() => {
						woDocumentStore.setFromDocument(workOrder)
						$lastWorkOrderType = workOrder.workOrderType.id
					}}
					let:option
				>
					<option value={option}>{option?.name}</option>
					<svelte:fragment slot="append">
						<Button
							outline
							iconClass="cog"
							href={asr.makePath('app.configuration.work-order-type', { plantId: workOrder.plant.id })}
							title={translate('workOrder.configureWoTypesTitle', 'Configure Work Order Types')}
						></Button>
					</svelte:fragment>
				</Select>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				{#if workOrder.workOrderType.productBatchRequired !== 'HIDE'}
					<Autocomplete
						label={translate('workOrder.productBatchLabel', 'Product Batch')}
						title={translate(
							'workOrder.productBatchTitle',
							'(Optional) Choose a product batch for this work order. This will automatically set the product of any samples and add additional batch based thresholds to the calculations.',
						)}
						options={productBatchesMap[workOrder.plant.id]}
						required={workOrder.workOrderType.productBatchRequired === 'REQUIRE'}
						disabled={!canEditCurrentWorkOrder ||
							(workOrder.productBatch && !hasPermission('WORK_ORDERS_CAN_CHANGE_BATCH', workOrder.plant.id)) ||
							(!workOrder.productBatch && !hasPermission('WORK_ORDERS_CAN_SET_BATCH', workOrder.plant.id))}
						getLabel={option => (option ? `${option?.product?.name} - ${option?.name}` : '')}
						bind:value={workOrder.productBatch}
						on:change={() => woDocumentStore.setFromDocument(workOrder)}
					></Autocomplete>
				{/if}
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<!--TODO title="Which plant the current work order is taking place at." -->
				<SiteAutocomplete
					label={translate('workOrder.plantLabel', 'Plant')}
					options={plants}
					disabled={!canEditCurrentWorkOrder}
					bind:value={workOrder.plant}
					on:change={() => woDocumentStore.setFromDocument(workOrder)}
				/>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.assignToGroupLabel', 'Assign to WO Group')}
					emptyText={translate('workOrder.assignToGroupEmptyText', 'All Users')}
					title={translate('workOrder.assignToGroupTitle', 'The group of users this work order is currently assigned to. Generally, only users within this group can edit the work order.')}
					value={workOrder.assignedToGroup?.id ?? null}
					disabled={!canEditCurrentWorkOrder}
					on:change={event => {
						if (event.target instanceof HTMLSelectElement) {
							const value = parseInt(event.target.value, 10)
							workOrder.assignedToGroup = value ? (workerGroups.find(group => group.id === value) ?? null) : null
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				>
					{#each workerGroups as option}
						<option value={option?.id}>{option?.name}</option>
					{/each}
					{#if workOrder.assignedToGroup && !workerGroups.find(group => group.id === workOrder.assignedToGroup?.id)}
						<option
							disabled
							value={workOrder.assignedToGroup.id}>{workOrder.assignedToGroup.name}</option
						>
					{/if}
				</Select>
			</div>
			<div class="col-12 col-md-6 col-xl-2">
				<Select
					label={translate('workOrder.statusLabel', 'Status')}
					title={translate('workOrder.statusTitle', 'Set the status of all or some of the items on this work order.')}
					options={workOrderStatuses}
					showEmptyOption={false}
					bind:value={workOrder.documentStatus}
					on:change={() => {
						if (workOrder.documentStatus === 'CLOSED' && !confirm(translate('workOrder.confirmCloseWo', 'Are you sure you want to close this work order and all samples on it?'))) {
							workOrder.documentStatus = initialDocumentStatus
							return
						} else if (workOrder.documentStatus === 'CLOSED') {
							updateSampleStatuses('CLOSED')
						}
						woDocumentStore.setFromDocument(workOrder, true)
					}}
					disabled={(initialDocumentStatus !== 'CLOSED' && !canEditCurrentWorkOrder) || (initialDocumentStatus === 'CLOSED' && !hasPermission('WORK_ORDERS_CAN_REOPEN', workOrder.plant.id))}
					let:option
				>
					<option value={option}>{option && formatDocumentStatus(translate, option)} </option>
				</Select>
			</div>
		</div>
		<div class="form-row">
			<div class="col-12 col-md-6 col-xl-2">
				<Input
					readonly
					label={translate('workOrder.dateCreatedLabel', 'Date Created')}
					title={translate('workOrder.dateCreatedTitle', 'When the work order was initially created.')}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.dateCreated)}
					disabled={!canEditCurrentWorkOrder}
					on:change={event => setWoDateFieldFromInput(event, 'dateCreated')}
				></Input>
				<Input
					label={translate('workOrder.dateScheduledLabel', 'Date Scheduled')}
					title={translate(
						'workOrder.dateScheduledTitle',
						"The scheduled date/time when the work order should be performed. Note: if this work order gets cloned, the new work order's scheduled date/time will be moved to the time of copy, and all other date/time values will be based on the new scheduled date/time.",
					)}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.scheduled)}
					disabled={!canEditCurrentWorkOrder}
					on:change={event => setWoDateFieldFromInput(event, 'scheduled')}
				></Input>
			</div>
			{#if workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				<div class="col-12 col-md-6 col-xl-2">
					{#if workOrder.workOrderType.showDue}
						<Input
							label={translate('workOrder.dateDueLabel', 'Date Due')}
							title={translate('workOrder.dateDueTitle', 'The date the work order is due and must be completed by.')}
							hint={workOrder.due && new Date() > new Date(workOrder.due) ? translate('workOrder.pastDue', 'Past due') : ''}
							hintClass="text-danger"
							type="datetime-local"
							value={formatDateTimeForInput(workOrder.due)}
							disabled={!canEditCurrentWorkOrder}
							on:change={event => setWoDateFieldFromInput(event, 'due')}
						></Input>
					{/if}
					{#if workOrder.workOrderType.verificationRequired}
						<Input
							label={translate('workOrder.verificationDueLabel', 'Verification Due')}
							title={translate('workOrder.verificationDueTitle', 'The date the work order must be completed and verified by.')}
							hint={workOrder.verificationDue && new Date() > new Date(workOrder.verificationDue) ? translate('workOrder.pastDue', 'Past due') : ''}
							hintClass={'text-danger'}
							type="datetime-local"
							value={formatDateTimeForInput(workOrder.verificationDue)}
							disabled={!canEditCurrentWorkOrder}
							on:change={event => setWoDateFieldFromInput(event, 'verificationDue')}
						></Input>
					{/if}
				</div>
			{/if}
			<div class="col-12 col-md-6 col-xl-2">
				<div class="label-padding">
					<Checkbox
						label={translate('workOrder.verifyLabel', 'Verified by:')}
						title={translate('workOrder.verifyTitle', 'Check this box to certify that this work order has been inspected and verified.')}
						checked={!!workOrder.verifiedByUser?.id}
						disabled={!canEditCurrentWorkOrder || !hasPermission('WORK_ORDERS_CAN_VERIFY', workOrder.plant.id)}
						required={workOrder.workOrderType.verificationRequired}
						on:change={onVerifiedChange}
					></Checkbox>
				</div>
				<Input
					readonly
					labelParentClass="mb-1"
					title={translate('workOrder.verifiedByTitle', 'The user that inspected and verified this work order (if applicable).')}
					showLabel={false}
					value={workOrder.verifiedByUser?.fullName ?? `Not Verified${workOrder.workOrderType.verificationRequired ? ' (Required)' : ''}`}
				></Input>
				<Input
					readonly
					label={translate('workOrder.verifiedOnLabel', 'On:')}
					title={translate('workOrder.verifiedOnTitle', 'The date on which this work order was inspected and verified (if applicable).')}
					type="datetime-local"
					value={formatDateTimeForInput(workOrder.verifiedOn)}
				></Input>
			</div>
			<div
				class="col-12 col-md-6"
				class:col-xl-3={workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				class:col-xl-4={!workOrder.workOrderType.showDue && !workOrder.workOrderType.verificationRequired}
			>
				<Textarea
					class="flex-grow-1"
					labelParentClass="h-100 d-flex flex-column"
					label={translate('workOrder.internalNotesLabel', 'Internal Notes')}
					title={translate('workOrder.internalNotesTitle', 'The internal notes of a work order track any information that should be kept internal to the software.')}
					value={workOrder.internalNotes}
					disabled={!canEditCurrentWorkOrder}
					on:change={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							workOrder.internalNotes = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				></Textarea>
			</div>
			<div
				class="col-12 col-md-6"
				class:col-xl-3={workOrder.workOrderType.showDue || workOrder.workOrderType.verificationRequired}
				class:col-xl-4={!workOrder.workOrderType.showDue && !workOrder.workOrderType.verificationRequired}
			>
				<Textarea
					class="flex-grow-1"
					labelParentClass="h-100 d-flex flex-column"
					label={translate('workOrder.samplingInstructionsLabel', 'Sampling Instructions')}
					title={translate('workOrder.samplingInstructionsTitle', 'The sampling instructions can contain any information to be given to the individuals performing the work order.')}
					value={workOrder.instructions}
					disabled={!canEditCurrentWorkOrder}
					on:change={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							workOrder.instructions = event.target.value
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				></Textarea>
			</div>
		</div>
	</CollapsibleCard>

	<div class="card">
		<div class="card-header d-flex justify-content-between">
			<h5 class="mb-0">{translate('workOrder.samplesCount', 'Samples ({{sampleCount}})', { sampleCount: workOrder.samples.length })}</h5>
			<span>
				<Checkbox
					inline
					type="radio"
					label={translate('workOrder.viewButtonLabel', 'View')}
					trueLabel=""
					trueIcon="table"
					falseLabel=""
					falseIcon="list"
					title={translate('workOrder.viewButtonTitle', 'Whether to view the samples in a table or a list view. List view is recommended for mobile devices.')}
					checked={$sampleViewMode === 'TABLE'}
					on:change={event => {
						if (event.target instanceof HTMLInputElement) {
							$sampleViewMode = stringToBoolean(event.target.value) ? 'TABLE' : 'LIST'
						}
						woDocumentStore.setFromDocument(workOrder)
					}}
				></Checkbox>
			</span>
		</div>
		<div
			class="card-body"
			class:p-0={$sampleViewMode === 'LIST'}
		>
			{#if $sampleViewMode === 'TABLE'}
				<Table
					tableId="sample-table"
					stickyHeader
					filterEnabled
					columnHidingEnabled
					responsive
					selectionEnabled
					parentClass="mh-60vh"
					perPageCount={10}
					idProp="uuid"
					rowSelectionIdProp="uuid"
					rows={displaySamples}
					columns={displayColumns}
					headerColumnClass="col d-flex flex-grow-1 mb-3"
					style="position: static;"
					bind:selectedRowIds
					bind:this={table}
					let:row
				>
					<svelte:fragment slot="header">
						<div class="d-flex align-self-end flex-grow-1 flex-wrap">
							<Checkbox
								inline
								label={translate('workOrder.showOptionsLabel', 'Show options and values')}
								bind:checked={$showOptions}
							></Checkbox>
							{#if workOrder.workOrderType.showSamplingDetail}
								<Checkbox
									inline
									label={translate('workOrder.showCollectionDetailLabel', 'Show collection detail')}
									bind:checked={$showCollectionDetail}
								></Checkbox>
							{/if}
							{#if workOrder.workOrderType.showTestingDetail}
								<Checkbox
									inline
									label={translate('workOrder.showTestingDetailLabel', 'Show testing detail')}
									bind:checked={$showTestingDetail}
								></Checkbox>
							{/if}
							<!-- This div pushes the last checkbox to the right -->
							<div class="flex-grow-1"></div>
							<Checkbox
								inline
								label={closedSampleCount
									? translate('workOrder.showClosedSamplesCountLabel', 'Show {{closedSampleCount}} closed samples', { closedSampleCount })
									: translate('workOrder.showClosedSamplesLabel', 'Show closed samples')}
								disabled={closedSampleCount === 0}
								bind:checked={$showClosedSamples}
							></Checkbox>
						</div>
					</svelte:fragment>
					<tr slot="no-rows"
						><td
							class="text-center"
							colspan={columns.length}>{translate('workOrder.noSamplesForFilters', 'No samples matching the given filters.')}</td
						></tr
					>
					{@const rowPlantId = row.plant?.id ?? workOrder.plant.id}
					{@const initialSampleStatus = initialSampleStatuses[row.uuid]}
					<tr
						class:table-primary={selectedRowIds.includes(row.uuid)}
						on:contextmenu={event => {
							if (!event.ctrlKey) {
								event.preventDefault()
								contextMenuSample = row
								sampleContextMenu.open(event)
							}
						}}
						on:click={() => {
							table.rowClick(row)
							if (selectedRowIds.length === 1) {
								selectedSample = displaySamples.find(sample => sample.uuid === selectedRowIds[0]) ?? null
							} else {
								selectedSample = null
							}
						}}
					>
						<Td property="attn"
							><Attention
								sample={row}
								sampleModified={row.uuid in $sampleCrudStore.updated}
								on:save={() => mediator.call('saveWorkOrder')}
								on:close={() => {
									workOrder.samples[row.originalIndex].status = 'CLOSED'
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
								on:attachments={() => {
									selectedSample = row
									showAttachmentsModal = true
								}}
							></Attention></Td
						>
						<Td
							enterGoesDown
							stopPropagation
							property="analysis[name]"
						>
							<Autocomplete
								id="analysis-{row.uuid}"
								label={translate('workOrder.analysisLabel', 'Analysis')}
								showLabel={false}
								options={analysesMap[rowPlantId]}
								title={row.analysis?.name ?? ''}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								getLabel={option => option?.name ?? ''}
								value={row.analysis}
								on:change={async event => {
									if (!confirmActionThatRequiresReVerify()) {
										return
									}
									const analysis = (await analysesMap[workOrder.plant.id]).find(analysis => analysis.id === event.detail.id)
									if (analysis) {
										workOrder.samples[row.originalIndex].analysis = {
											id: analysis.id,
											name: analysis.name,
											requireAuthentication: analysis.requireAuthentication,
										}
										workOrder.samples[row.originalIndex].sampleValues = analysisOptionsToBlankSampleValues(analysis.options, row.id)
										sampleCrudStore.update(workOrder.samples[row.originalIndex])
										restrictionTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
										documentTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
									}
								}}
							></Autocomplete>
						</Td>
						<Td property="plant[name]"><span style="white-space:nowrap;">{row.plant?.name ?? 'N/A'}</span></Td>
						<Td
							enterGoesDown
							stopPropagation
							property="product[name]"
						>
							<Autocomplete
								label={translate('workOrder.productLabel', 'Product')}
								labelParentClass="product-location-select"
								showLabel={false}
								showAppend={!!row.product?.attachmentCount}
								options={getDisplayProducts(rowPlantId)}
								title={row.product?.name ?? undefined}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								getLabel={option => option?.name ?? ''}
								emptyValue={null}
								value={row.product}
								on:change={async event => {
									if (!confirmActionThatRequiresReVerify()) {
										return
									}
									if (event.detail) {
										const product = (await productsMap[rowPlantId]).find(product => product.id === event.detail?.id)
										workOrder.samples[row.originalIndex].product = product ? klona(product) : null
									}

									sampleCrudStore.update(workOrder.samples[row.originalIndex])
									restrictionTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
									documentTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
								}}
							>
								<Button
									slot="append"
									iconClass="photo-video"
									title={translate('workOrder.productImagesTitle', 'View product images')}
									tabindex={-1}
									isLoading={!!row.product && isLoadingProductLocationImages(productLocationAttachmentModal.isLoading, 'PRODUCT', row.product.id ?? 0)}
									on:click={async () => {
										if (row.product) {
											await showProductLocationImages('PRODUCT', row.product.id, row.product.name)
										}
									}}>{row.product?.attachmentCount}</Button
								>
							</Autocomplete>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="location[location]"
						>
							<Autocomplete
								label={translate('workOrder.locationLabel', 'Location')}
								labelParentClass="product-location-select"
								showLabel={false}
								options={locationsMap[rowPlantId]}
								title={row.location?.location ?? undefined}
								value={row.location}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								showAppend={!!row.location?.attachmentCount}
								getLabel={option => option?.location ?? ''}
								emptyValue={null}
								on:change={async event => {
									if (!confirmActionThatRequiresReVerify()) {
										return
									}
									if (event.detail) {
										const location = (await locationsMap[rowPlantId]).find(location => location.id === event.detail?.id)
										workOrder.samples[row.originalIndex].location = location ? klona(location) : null
									}

									sampleCrudStore.update(workOrder.samples[row.originalIndex])
									restrictionTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
									documentTree.fetchForSample(workOrder.samples[row.originalIndex], rowPlantId)
								}}
							>
								<Button
									slot="append"
									iconClass="photo-video"
									title={translate('workOrder.locationImagesTitle', 'View location images')}
									tabindex={-1}
									isLoading={!!row.location && isLoadingProductLocationImages(productLocationAttachmentModal.isLoading, 'LOCATION', row.location.id ?? 0)}
									on:click={async () => {
										if (row.location) {
											await showProductLocationImages('LOCATION', row.location.id, row.location.location)
										}
									}}
									>{row.location?.attachmentCount}
								</Button>
							</Autocomplete>
						</Td>
						<Td property="location[description]">
							<span class:text-muted={!row.location?.description}>{row.location?.description ?? 'N/A'}</span>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="testingComments"
						>
							<Textarea
								placeholder="N/A"
								style="min-height: 31px; height: 31px;"
								showLabel={false}
								value={row.testingComments}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									if (event.target instanceof HTMLTextAreaElement) {
										workOrder.samples[row.originalIndex].testingComments = event.target.value
									}
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Textarea>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="samplingComments"
						>
							<Textarea
								placeholder="N/A"
								style="min-height: 31px; height: 31px;"
								showLabel={false}
								value={row.samplingComments}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									if (event.target instanceof HTMLTextAreaElement) {
										workOrder.samples[row.originalIndex].samplingComments = event.target.value
									}
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Textarea>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="status"
						>
							<Select
								showLabel={false}
								options={workOrderStatuses}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus) && !(initialSampleStatus === 'CLOSED' && hasPermission('WORK_ORDERS_CAN_REOPEN', rowPlantId))}
								value={row.status}
								on:change={event => {
									if (event.target instanceof HTMLSelectElement) {
										const newStatus = event.target.value
										if (!isDocumentStatus(newStatus)) {
											return alert(translate('workOrder.invalidStatusAlert', 'Invalid status'))
										}

										const oldStatus = workOrder.samples[row.originalIndex].status
										const requiredValuesPresent = sampleMissingRequiredValuesForStatus(workOrder.samples[row.originalIndex], newStatus).length === 0
										const canPerformPartial = hasPermission('WORK_ORDERS_CAN_PERFORM_PARTIALLY_COMPLETED', rowPlantId)
										const canClosePartial = hasPermission('WORK_ORDERS_CAN_CLOSE_PARTIALLY_COMPLETED', rowPlantId)

										// If stuff isn't all the way filled out, we might not allow them to change the status
										if (!requiredValuesPresent && newStatus === 'CLOSED' && !canClosePartial) {
											workOrder.samples[row.originalIndex].status = oldStatus
											return alert(translate('workOrder.closeMissingRequiredOptionsAlert', 'All required options must be filled out before the sample can be closed'))
										} else if (
											!requiredValuesPresent &&
											newStatus === 'CLOSED' &&
											canPerformPartial &&
											!confirm(translate('workOrder.closeMissingRequiredOptionsConfirm', 'Some required options are not filled out. Are you sure you want to close this sample?'))
										) {
											return
										} else if (!requiredValuesPresent && newStatus === 'SAMPLED' && !canPerformPartial) {
											workOrder.samples[row.originalIndex].status = oldStatus
											return alert(translate('workOrder.performMissingRequiredOptionsAlert', 'All required options must be filled out before the sample can be performed'))
										} else if (
											!requiredValuesPresent &&
											newStatus === 'SAMPLED' &&
											canPerformPartial &&
											!confirm(translate('workOrder.performMissingRequiredOptionsConfirm', 'Some required options are not filled out. Are you sure you want to perform this sample?'))
										) {
											return
										}
										// Now we know they're allowed to change the status
										workOrder.samples[row.originalIndex].status = newStatus

										sampleCrudStore.update(workOrder.samples[row.originalIndex])
									}
								}}
								let:option
							>
								<option value={option}>{option && formatDocumentStatus(translate, option, true)}</option>
							</Select>
						</Td>

						{#if $showOptions}
							{#each row.sampleValues as sampleValue, displaySvIndex}
								{@const optionName = sampleValue?.analysisOption ? formatOptionName(sampleValue?.analysisOption) : null}
								{#if sampleValue && optionName && optionsToShow.has(optionName)}
									{@const svOgIndex = sampleValue.svOgIndex}
									<Td
										enterGoesDown
										stopPropagation
										property="sampleValues[{displaySvIndex}][result]"
									>
										<!-- restriction/document store values are referenced below to make it reactive -->
										<OptionInput
											{allowShowThresholdsTable}
											disabled={!computeCanEditSampleValue(canEditCurrentWorkOrder, initialSampleStatus, row, sampleValue)}
											labelType="COMPACT"
											sample={row}
											sampleValue={workOrder.samples[row.originalIndex]?.sampleValues[svOgIndex]}
											showLabel={hasMultipleAnalyses}
											showModifiedIcons={$showModifiedIcons}
											restriction={$restrictionTree &&
												restrictionTree.get({
													analysisOptionId: sampleValue.analysisOption.id,
													plantId: rowPlantId,
													locationId: row.location?.id ?? null,
													productId: row.product?.id ?? null,
												})}
											document={$documentTree &&
												documentTree.get({
													analysisId: row.analysis.id,
													analysisOptionId: sampleValue.analysisOption.id,
													plantId: rowPlantId,
													productId: row.product?.id ?? null,
													severityClassId: row.location?.severityClass?.id ?? null,
												})}
											{workOrder}
											{choicesMap}
											on:change={event => {
												if (!confirmActionThatRequiresReVerify()) {
													return
												}
												workOrder.samples[row.originalIndex].sampleValues[svOgIndex].result = event.detail.value
												if (workOrder.samples[row.originalIndex].status === 'OPEN') {
													workOrder.samples[row.originalIndex].status = 'SAMPLED'
												}
												sampleCrudStore.update(workOrder.samples[row.originalIndex])
												getDefaultSampleValues(workOrder.samples[row.originalIndex], row.originalIndex)
												if (sampleValue?.id) {
													woDocumentStore.sampleValueUpdated(sampleValue.id)
												}
											}}
										></OptionInput>
									</Td>
								{:else if !sampleValue || !optionName}
									<Td property="sampleValues[{displaySvIndex}][result]"><i class="text-muted">N/A</i></Td>
								{/if}
							{/each}
						{/if}
						<Td
							enterGoesDown
							stopPropagation
							property="scheduled"
						>
							<Input
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.scheduled)}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									setSampleDateFieldFromInput(event, 'scheduled', row.originalIndex)
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="due"
						>
							<Input
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.due)}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									setSampleDateFieldFromInput(event, 'due', row.originalIndex)
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="performed"
						>
							<Input
								readonly
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.performed)}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={() => sampleCrudStore.update(workOrder.samples[row.originalIndex])}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="collectedBy[fullName]"
						>
							<span
								style="white-space: nowrap"
								class:text-muted={!row.collectedBy?.fullName}>{row.collectedBy?.fullName ?? 'N/A'}</span
							>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="tagNumber"
						>
							<Input
								showLabel={false}
								value={row.tagNumber ?? ''}
								placeholder={translate('common:autoAssigned', 'Auto-assigned')}
								disabled={!hasPermission('WORK_ORDERS_CAN_EDIT_TAG_NUMBER', rowPlantId) || !computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									if (event.target instanceof HTMLInputElement) {
										workOrder.samples[row.originalIndex].tagNumber = event.target.value
									}
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="incubationBegan"
						>
							<Input
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.incubationBegan)}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									setSampleDateFieldFromInput(event, 'incubationBegan', row.originalIndex)
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="incubationEnded"
						>
							<Input
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.incubationEnded)}
								disabled={!computeCanEditSample(canEditCurrentWorkOrder, initialSampleStatus)}
								on:change={event => {
									setSampleDateFieldFromInput(event, 'incubationEnded', row.originalIndex)
									sampleCrudStore.update(workOrder.samples[row.originalIndex])
								}}
							></Input>
						</Td>
						<Td property="platesReadBy[fullName]">
							<span
								style="white-space: nowrap"
								class:text-muted={!row.platesReadBy?.fullName}>{row.platesReadBy?.fullName ?? 'N/A'}</span
							>
						</Td>
					</tr>
				</Table>
			{:else if $sampleViewMode === 'LIST'}
				<SampleListGroup
					{allowShowThresholdsTable}
					{locationsMap}
					{productsMap}
					{restrictionTree}
					{documentTree}
					{choicesMap}
					bind:workOrder
					bind:selectedRowIds
					bind:showClosedSamples={$showClosedSamples}
					on:showProductLocationImages={({ detail }) => showProductLocationImages(detail.entityType, detail.entityId, detail.entityName)}
					on:sampleValuesChanged={({ detail: { ids } }) => woDocumentStore.sampleValueUpdated(ids)}
				></SampleListGroup>
			{/if}
		</div>
		<TableCardFooter
			showCount={false}
			itemDescriptor={translate('workOrder.footerSamplesLabel', 'Samples')}
			exportFileName="work-order-{workOrder.id ?? 'unsaved'}-{workOrder.title ?? workOrder.workOrderType.name}-samples"
			idKey="uuid"
			clearSelectionButtonClass="mobile-button-100"
			exportButtonClass="mobile-button-100"
			items={buildCsvItems(workOrder.samples)}
			footerClass="flex-column flex-sm-row"
			bind:selectedIds={selectedRowIds}
			bind:this={tableCardFooter}
		>
			<Button
				outline
				color="success"
				size="sm"
				iconClass="plus"
				class="mobile-button-100"
				title={translate('workOrder.newSampleTitle', 'Create a New Sample')}
				disabled={!canEditCurrentWorkOrder}
				on:click={newSampleClick}
				>{translate('workOrder.newSampleLabel', 'New Sample')}...
			</Button>
			<Button
				outline
				color="danger"
				size="sm"
				iconClass="trash"
				class="mobile-button-100"
				disabled={!selectedRowIds.length || !canDeleteSelectedSamples(selectedRowIds)}
				title={translate('workOrder.deleteSampleTitle', 'Delete the selected sample(s) from the system? This is permanent and cannot be undone.')}
				on:click={() => {
					if (
						confirm(
							selectedRowIds.length > 1
								? translate('workOrder.deleteSelectedSamplesConfirm', `Are you sure you want to delete {{sampleCount}} samples?`, { sampleCount: selectedRowIds.length })
								: translate('workOrder.deleteSelectedSampleConfirm', 'Are you sure you want to delete the selected sample?'),
						) &&
						confirmActionThatRequiresReVerify()
					) {
						const indeces = selectedRowIds.map(uuid => workOrder.samples.findIndex(sample => sample.uuid === uuid))
						const samplesToDelete = indeces.map(index => workOrder.samples[index])
						samplesToDelete.forEach(sample => {
							if (sample.id) {
								sampleCrudStore.delete(sample)
							}
						})
						indeces.forEach(index => {
							workOrder.samples.splice(index, 1)
							workOrder.samples = workOrder.samples
						})
						selectedRowIds = []
					}
				}}
				>{selectedRowIds.length > 1 ? translate('workOrder.deleteSamples', 'Delete Samples') : translate('workOrder.deleteSample', 'Delete Sample')}...
			</Button>
			<Button
				outline
				size="sm"
				iconClass="check-double"
				class="mobile-button-100"
				disabled={!displaySamples.length}
				on:click={() => {
					if (selectedRowIds.length === workOrder.samples.length) {
						selectedRowIds = []
					} else {
						selectedRowIds = workOrder.samples.map(sample => sample.uuid)
					}
				}}
			>
				{selectedRowIds.length && selectedRowIds.length === workOrder.samples.length ? translate('workOrder.selectNone', 'Select None') : translate('workOrder.selectAll', 'Select All')}
			</Button>

			<svelte:fragment slot="right">
				{#if $sampleViewMode === 'TABLE'}
					<Button
						outline
						size="sm"
						iconClass="paperclip"
						title={translate('workOrder.attachmentsButtonTitle', 'Open the attachment interface, where you can view and manage attachments for the selected sample.')}
						class="mobile-button-100"
						disabled={!selectedSample || !selectedSample.plant || !hasPermission('WORK_ORDERS_CAN_EDIT', selectedSample.plant.id)}
						on:click={() => (showAttachmentsModal = true)}
					>
						{translate('workOrder.attachmentsButtonLabel', 'Attachments')}...
					</Button>
				{/if}
				<!-- 						<Button
							outline
							disabled
							size="sm"
							iconClass="file-magnifying-glass"
							title="Investigation screen has not been implemented yet"
							href={asr.makePath(null)}
							>Begin Investigation...
						</Button> -->
			</svelte:fragment>
		</TableCardFooter>
	</div>
</div>
<div class="card-footer d-flex justify-content-between flex-fill border-bottom">
	<Dropdown
		split
		outline
		size="sm"
		color="success"
		iconClass="plus"
		href={asr.makePath(null, { workOrderId: null, lastResetTime: Date.now() })}
		disabled={!hasPermission('WORK_ORDERS_CAN_ADD', $session.siteId)}
		>{translate('workOrder.newWorkOrderLabel', 'New Work Order')}
		<svelte:fragment slot="dropdownItems">
			<DropdownItem
				icon="copy"
				disabled={!workOrder.id}
				on:click={async () => {
					showConfirmCopyModal = true
					await tick()
					document.getElementById('copy-wo-and-values-button')?.focus()
				}}>{translate('workOrder.cloneWorkOrderLabel', 'Clone Work Order')}</DropdownItem
			>
		</svelte:fragment>
	</Dropdown>
	<Dropdown
		split
		outline
		size="sm"
		iconClass="print"
		title={translate('workOrder.printTitle', 'Print the current work order, or select a type of sample tag to print.')}
		on:click={() => printWorkOrder()}
	>
		{translate('workOrder.printLabel', 'Print')}
		<svelte:fragment slot="dropdownItems">
			<DropdownItem
				icon="clipboard"
				on:click={() => printWorkOrder()}>{translate('workOrder.printWoLabel', 'Print WO')}</DropdownItem
			>
			<DropdownItem
				icon="tag"
				title={translate('workOrder.printSampleTagTitle', 'Prints a sample tag for each sample in the work order.')}
				on:click={() => printTags('Work Order Tags', 'ALL')}>{translate('workOrder.printSampleTagsLabel', 'Print Sample Tags')}</DropdownItem
			>
			<DropdownItem
				icon="tag"
				title={translate('workOrder.printTestingTagTitle', 'Prints a testing tag for each sample in the work order.')}
				on:click={() => printTags('Work Order Testing Tags', 'ALL')}>{translate('workOrder.printTestingTagsLabel', 'Print Testing Tags')}</DropdownItem
			>
		</svelte:fragment>
	</Dropdown>
</div>

<ContextMenu bind:this={sampleContextMenu}>
	<h6 class="dropdown-header">{translate('workOrder.sampleTagNumberTitle', 'Sample #{{- tagNumber}}', { tagNumber: contextMenuSample?.tagNumber })}</h6>
	<DropdownItem
		icon="print"
		on:click={() => printTags('Work Order Tags', 'SINGLE')}>{translate('workOrder.printSampleTagLabel', 'Print Sample Tag')}</DropdownItem
	>
	<DropdownItem
		icon="print"
		on:click={() => printTags('Work Order Testing Tags', 'SINGLE')}>{translate('workOrder.printTestingTagLabel', 'Print Testing Tag')}</DropdownItem
	>
	<!-- <DropdownItem disabled>Begin Investigation</DropdownItem> -->
	<DropdownItem
		icon="file-csv"
		on:click={() => tableCardFooter.downloadCsv()}>{translate('workOrder.exportAllToCsvLabel', 'Export All Samples to CSV')}</DropdownItem
	>
	<DropdownItem
		icon="file-csv"
		disabled={!selectedRowIds.length}
		on:click={() => tableCardFooter.downloadSelectedCsv()}>{translate('workOrder.exportSelectedToCsvLabel', 'Export Selected Samples to CSV')}</DropdownItem
	>
</ContextMenu>

<Modal
	modalId="confirm-copy-modal"
	bind:show={showConfirmCopyModal}
	title={translate('workOrder.confirmCloneTitle', 'Confirm Manual Work Order Copy')}
	modalDialogClass="min-width-fit-content"
	confirmShown={false}
	cancelShown={false}
>
	<div class="text-center">{translate('workOrder.confirmCloneSubtitle', 'Manually copy the current work order document?')}</div>
	<div slot="modalFooter">
		<Button
			id="copy-wo-and-values-button"
			outline
			class="mobile-button-100"
			iconClass="folder-plus"
			on:click={() => {
				cloneWo(true)
			}}>{translate('workOrder.copyWoValuesLabel', 'Copy WO and Values')}</Button
		>
		<Button
			outline
			class="mobile-button-100"
			iconClass="folder"
			on:click={() => {
				cloneWo()
			}}>{translate('workOrder.copyWoLabel', 'Copy WO Only')}</Button
		>
		<Button
			color="secondary"
			class="mobile-button-100"
			iconClass="xmark"
			on:click={() => {
				showConfirmCopyModal = false
			}}>{translate('workOrder.cancelLabel', 'Cancel')}</Button
		>
	</div>
</Modal>

<Modal
	closeShown={false}
	cancelShown={false}
	title={translate('workOrder.attachmentsTitle', 'Attachments')}
	backdropClickCancels={false}
	bind:show={showAttachmentsModal}
	modalSize="xxl"
	on:confirm={() => (showAttachmentsModal = false)}
>
	{#if selectedSample}
		<SampleAttachments
			sample={selectedSample}
			on:attachmentChange={({ detail }) => {
				if (selectedSample) {
					selectedSample.attachments = detail
					const index = workOrder.samples.findIndex(sample => selectedSample && sample.uuid === selectedSample.uuid)
					if (index !== -1) {
						workOrder.samples[index].attachments = detail
					}
				}
			}}
		></SampleAttachments>
	{:else}
		<div class="text-center">{translate('workOrder.noSampleSelected', 'No Sample Selected.')}</div>
	{/if}
</Modal>

<Modal
	modalId="new-sample-modal"
	bind:show={showNewSampleModal}
	title={translate('workOrder.newSampleTitle', 'New Sample')}
	confirmButtonDisabled={!newSampleTemplate.analysis}
	on:confirm={() => {
		newSample(newSampleTemplate)
		showNewSampleModal = false
	}}
	on:close={() => {
		showNewSampleModal = false
	}}
>
	<Autocomplete
		required
		label={translate('workOrder.analysisLabel', 'Analysis')}
		id="new-sample-analysis"
		options={analysesMap[workOrder.plant.id]}
		getLabel={option => option?.name ?? ''}
		bind:value={newSampleTemplate.analysis}
	></Autocomplete>
	{#if workOrder.workOrderType.showProduct !== 'NONE'}
		<Autocomplete
			label={translate('workOrder.productLabel', 'Product')}
			options={productsMap[workOrder.plant.id]}
			getLabel={option => option?.name ?? ''}
			emptyValue={null}
			bind:value={newSampleTemplate.product}
		></Autocomplete>
	{/if}
	{#if workOrder.workOrderType.showLocation}
		<Autocomplete
			label={translate('workOrder.locationLabel', 'Location')}
			options={locationsMap[workOrder.plant.id]}
			emptyValue={null}
			getLabel={option => option?.location ?? ''}
			bind:value={newSampleTemplate.location}
		></Autocomplete>
		{#if workOrder.workOrderType.showLocationDescription && newSampleTemplate.location?.description}
			{newSampleTemplate.location?.description}
		{/if}
	{/if}
</Modal>

<ImageViewer
	title={translate('workOrder.entityImagesTitle', '{{- entityName}} Images', { entityName: productLocationAttachmentModal.entityName })}
	files={productLocationAttachmentModal.files}
	bind:show={productLocationAttachmentModal.show}
	bind:currentPhotoIndex={productLocationAttachmentModal.currentPhotoIndex}
/>

<ReportJobModal
	loadReports={reportType => Promise.resolve(reports.filter(report => report.type === reportType))}
	loadPrinters={() => Promise.resolve(printers)}
	loadDefaultPrinter={async reportType => {
		const { data } = await getDefaultReportPrinter.fetch({
			variables: {
				input: {
					reportType,
				},
			},
		})
		return data?.getDefaultReportPrinter?.printer ?? ''
	}}
	saveReportJobs={async reportJobs => {
		await Promise.all(
			reportJobs.map(({ parameters, ...reportJob }) =>
				createReportQueueJob.mutate({
					newReportQueue: {
						...reportJob,
						parameters: Object.entries(parameters).map(([parameterName, value]) => ({ parameterName, value })),
					},
				}),
			),
		)
	}}
	savePrinterPreference={async (printer, reportType) => {
		await setDefaultReportPrinter.mutate({
			input: {
				reportType,
				printer,
				plantId: workOrder.plant.id,
				userId: $session.userAccountId,
			},
		})
	}}
	showPrint={!!printers.length}
	{generatePdfPreview}
	bind:favoriteReportNames={$favoriteReports}
	bind:this={reportJobModal}
></ReportJobModal>

<style>
	.label-padding {
		padding-top: calc(0.375rem + 1px);
		padding-bottom: calc(0.375rem + 1px);
	}

	:global(.min-width-fit-content) {
		min-width: fit-content;
	}

	:global(.form-group.product-location-select .input-group) {
		flex-wrap: nowrap !important;
	}

	:global(.form-group.product-location-select select),
	:global(.form-group.product-location-select input) {
		min-width: 175px !important;
	}

	:global(#sample-table td) {
		vertical-align: bottom !important;
	}

	:global(#sample-table td .form-group) {
		margin-bottom: 0 !important;
	}
</style>
