export default {
    "name": "AmAnalysisOptionsChoicesQuery",
    "kind": "HoudiniQuery",
    "hash": "e8b1da33f5966dce5bf669f957cbaa4ef61e82854127e61d0123b8528be7746b",

    "raw": `query AmAnalysisOptionsChoicesQuery($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
  analysisOptions(filter: $filter, pagination: $pagination) {
    data {
      ...AnalysisManagementOptionData
      choices {
        ...AnalysisManagementChoiceData
        id
      }
      id
    }
  }
}

fragment AnalysisManagementOptionData on AnalysisOption {
  id
  active
  analysisId
  option
  unit
  valueType
  defaultValue
  defaultType
  thresholdType
  requiredToPerform
  requiredToClose
  informational
  rank
  product {
    name
    id
  }
  rules {
    id
    analysisOptionId
    active
    restriction
    outcome
    description
    created
    tags {
      id
      name
      active
      entityType
    }
  }
  __typename
}

fragment AnalysisManagementChoiceData on AnalysisOptionChoice {
  active
  analysisOptionId
  plantId
  constraint
  boundaryType
  choice
  id
  product {
    name
    id
  }
  severityClass {
    id
    name
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptions": {
                "type": "AnalysisOptionResponse",
                "keyRaw": "analysisOptions(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOption",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "analysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisId",
                                        "visible": true
                                    },

                                    "option": {
                                        "type": "String",
                                        "keyRaw": "option",
                                        "visible": true
                                    },

                                    "unit": {
                                        "type": "String",
                                        "keyRaw": "unit",
                                        "visible": true
                                    },

                                    "valueType": {
                                        "type": "ValueType",
                                        "keyRaw": "valueType",
                                        "visible": true
                                    },

                                    "defaultValue": {
                                        "type": "String",
                                        "keyRaw": "defaultValue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultType": {
                                        "type": "DefaultType",
                                        "keyRaw": "defaultType",
                                        "visible": true
                                    },

                                    "thresholdType": {
                                        "type": "ThresholdType",
                                        "keyRaw": "thresholdType",
                                        "visible": true
                                    },

                                    "requiredToPerform": {
                                        "type": "Boolean",
                                        "keyRaw": "requiredToPerform",
                                        "visible": true
                                    },

                                    "requiredToClose": {
                                        "type": "Boolean",
                                        "keyRaw": "requiredToClose",
                                        "visible": true
                                    },

                                    "informational": {
                                        "type": "Boolean",
                                        "keyRaw": "informational",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "rules": {
                                        "type": "AnalysisOptionRule",
                                        "keyRaw": "rules",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "analysisOptionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "analysisOptionId",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "restriction": {
                                                    "type": "RestrictionType",
                                                    "keyRaw": "restriction",
                                                    "visible": true
                                                },

                                                "outcome": {
                                                    "type": "OutcomeType",
                                                    "keyRaw": "outcome",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "Timestamp",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "tags": {
                                                    "type": "EntityTag",
                                                    "keyRaw": "tags",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            },

                                                            "entityType": {
                                                                "type": "EntityTagType",
                                                                "keyRaw": "entityType",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "choices": {
                                        "type": "AnalysisOptionChoice",
                                        "keyRaw": "choices",

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "analysisOptionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "analysisOptionId",
                                                    "visible": true
                                                },

                                                "plantId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "plantId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "constraint": {
                                                    "type": "ConstraintType",
                                                    "keyRaw": "constraint",
                                                    "visible": true
                                                },

                                                "boundaryType": {
                                                    "type": "BoundaryType",
                                                    "keyRaw": "boundaryType",
                                                    "visible": true
                                                },

                                                "choice": {
                                                    "type": "String",
                                                    "keyRaw": "choice",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "product": {
                                                    "type": "Product",
                                                    "keyRaw": "product",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "AnalysisManagementChoiceData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AnalysisManagementOptionData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisOptionFilter": {
                "activeOnly": "Boolean",
                "analysisIds": "PositiveInt",
                "ids": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b051893fd02bccaf98bf1b0b1132f2b3c6d7f841ba5817a49cf7fdc0f9c73648";